interface customProps {
    children: any,
    bg?: string,
}

const CardLayout = (props: customProps) => {
    return (
        <div className={`${props?.bg ? props?.bg : 'bg-white'}  rounded-xl  border-[#E9E9E9] border shadow-[79_79_79_0.08] md:px-9 max-md:px-3 py-5 max-md:py-3 mb-[10px]`}>
            <main className="content">
                {props?.children}
            </main>
        </div>
    );
}

export default CardLayout;
