import { useState } from "react";
import Icon from "../icons/Icons"
import { useTranslation } from "react-i18next";

interface customProps {
    activeTab: boolean;
    getNoteData(text:string):void;
}

const AddNote = (props: customProps) => {

    const [tabActive, setTabActive] = useState(props.activeTab);
    const [noteInput, setnoteInput] = useState<any>([]);
    const { t, i18n } = useTranslation();

    const getValue = (e:any) =>{
        props.getNoteData(e.target.value)
    }
    
    return (
        <>
            <div>
                <div className="flex flex-row justify-between items-start cursor-pointer">
                    <div className="flex flex-col">
                        <label className="font-medium text-[15px] block">{t("Add note",i18n.language)}</label>
                    </div>
                </div>
                <div className="kmBox mt-[20px]">
                    <div className="kmBoxList flex flex-wrap gap-[5px]">
                        <textarea placeholder={t("Add your note",i18n.language)} onChange={(e)=>getValue(e)} className="bg-[#FFFFFF] border-[1px] border-[#D0D0D0] w-full rounded-md  placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-none max-w-[516px]" rows={4}></textarea>
                    </div>
                    <div className="pt-[30px] pb-6">
                        <div className="h-[1px] bg-[#E7E7E7] w-full"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNote;