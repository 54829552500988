import { t } from "i18next";
import i18n from "../../i18n";
import Icon from "../icons/Icons";
import { setItem } from "../../util/storageService";

interface customProps {
  title: string;
  text: string;
  wip: string;
}

const BookingConfirmed = (props:customProps) => {
    return (
        <>
            <div className="bookingCnfirm flex flex-col items-center mt-8 mb-14">
                <div className="mb-4">
                    <Icon name="confirmed"/>
                </div>
                <div className="font-medium text-2xl text-black">
                    {props?.title}
                </div>
                <span className="block mt-2 font-medium text-lg text-black text-center">{props?.text}</span>
                <p className="mt-4">
                    <span className="font-medium text-xs text-muted">{t("WIP Number",i18n.language)}:</span>
                    <span className="font-medium text-sm text-black"> {props?.wip}</span>
                </p>
            </div>
        </>
    )
}

export default BookingConfirmed;
