import { useEffect, useState } from "react";
import CarDetails from "../components/cards/CarDetails";
import PreviewCard from "../components/cards/PreviewBookingCard";
import { UserData } from "../type/UserDetails";
import { clearStorage, getItem, setItem } from "../util/storageService";
import CardNames from "../util/CardNames";
import apiService from "../api/api";
import { navigateTo } from "../router/appRouter";
import i18n from "../i18n";
import FullLoader from "../components/common/Loader";
import handleApiError from "../util/ErrorService";
import CardLayout from "../components/common/Card";
import BookingConfirmed from "../components/misc/BookingConfirmed";
import { t } from "i18next";

const CancelBooking = () => {
  const [booingStatus, setBookingStatus] = useState(false);
  const [confirm, setConfirmBook] = useState(true);
  const [loader, setLoader] = useState(false);
  const wipNumber = getItem("cancel_wip_number");

  const userDetails: UserData = getItem("userDetails");
  const summary = getItem("userSummary");
  const odoMeterReading = summary?.find(
    (item: { card_name: string }) => item.card_name === CardNames?.ODO_MILEAGE
  );

  const handleComponent = (nextcomponent: string) => {};

  const backToHome = () => {
    navigateTo(`/${i18n.language}/`);
  };
  const rebook = () => {
    clearStorage();
    navigateTo(`/${i18n.language}/book-a-service`);
  };

  return (
    <>
      {loader && (
        <FullLoader
          fullLoader={true}
          iconColor="#023f88"
          class="w-[100px] text-primary"
        />
      )}

      <div className="max-w-[830px] mx-auto relative mt-10 mb-[50px] px-4">
        <div className="w-full">
          <div className="w-full">
            <CardLayout bg={"bg-[#FCFCFC]"}>
              <div className="relative">
                <div className="confirmBox">
                  <BookingConfirmed
                    title={t("Booking Cancelled",i18n.language)}
                    wip={wipNumber}
                    text={t("Your booking has been successfully canceled.",i18n.language)}
                  />
                </div>
              </div>
            </CardLayout>
          </div>

          <div className="masterBtn mt-8 flex items-center justify-start gap-4">
            <button
              className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md"
              onClick={() => backToHome()}
            >
              {t("Go Home", i18n.language)}
            </button>
            <button
              className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
              onClick={() => rebook()}
            >
              {t("Rebook Service", i18n.language)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelBooking;
