import { useEffect, useState } from "react";
import PlateNumber from "../components/cards/PlateNumber";
import { navigateTo } from "../router/appRouter";
import { getItem } from "../util/storageService";
import CardNames from "../util/CardNames";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const ManageService = () => {
  const [booingStatus, setBookingStatus] = useState(false);
  const [canEdit, setEdit] = useState<string>(CardNames?.PLATE_NUMBER);
  const plateNumber = getItem("plate_number_manage");
  const vin = getItem("vin_number_manage");
  const { t, i18n } = useTranslation();
const handleComponent = (nextcomponent: string) => {
    navigateTo("/en/manage-booking");
  };

  useEffect(() => {
    const step = getItem("currentStep");
    const userData = getItem("userDetails");
    const userSummary = getItem("userSummary");
    const plateNumber =
      userData?.data?.data?.user?.customerVehicles?.licensePlate;

    if (userData?.data?.data?.token) {
      navigateTo(`/${i18n.language}/manage-booking`);
    }
  }, [canEdit]);

  return (
    <div className="max-w-[830px] mx-auto relative mt-10 mb-[50px] px-4 md:mb-32">
      <div className="w-full">
        <h2 className="font-medium text-[22px] text-black mb-7">
         {t("Manage an Existing Service", i18n.language)}                
        </h2>
        <div className="w-full">
          <PlateNumber
            editPrompt={() => {}}
            flag={"manage-bookings"}
            edit={canEdit}
            cardActive={true}
            onSubmit={handleComponent}
            manageBookingLogin={true}
            search={plateNumber ? { plateNumber: plateNumber } : { vin: vin }}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageService;
