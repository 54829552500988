import Icon from "../../icons/Icons";

interface customProps {
    // title: string;
    // subTitle: string;
    // cancelModal(): void;
    confirmModal(): void;
}

const SessionExpired = (props: customProps) => {
    return (
        <>
            <div className="flex flex-col text-center">
                <div className="flex items-center justify-center mb-4">
                    <Icon name="expired" />
                </div>
                <div className="font-medium text-black text-2xl">
                    Session Expired
                </div>
                <p className="font-medium text-xs text-muted mt-3 mb-6">
                    Your session has expired due to inactivity.
                </p>
                <div className="masterBtn mt-0 mb-2">
                    <button className='h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md' onClick={props.confirmModal}>Go Home</button>
                </div>
            </div>
        </>
    )
}

export default SessionExpired;