import { useEffect, useState } from "react";
import Icon from "../icons/Icons";
import CardLayout from "../common/Card";
import CardNames from "../../util/CardNames";
import { getItem, setItem } from "../../util/storageService";
import { updateOrAddCardData } from "../../util/ManageSummary";
import apiService from "../../api/api";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

interface customProps {
  cardActive: boolean;
  onSubmit: (nextcomponent: string) => void;
  edit?: string;
  editPrompt(card: any): void;
}

const OdoMileage = (props: customProps) => {
  const [nextcomponent, setSetcomponent] = useState(CardNames?.DEALER_LOCATION);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [inputValue, setinputValue] = useState("");
  const [error, setError] = useState("");
  const [summaryData, setSummaryData] = useState<any>("");
  const currentSteplocal = getItem("currentStep");
  const userDetails = getItem("userDetails");
  const [currentStep, setCurrentStep] = useState(currentSteplocal);
  const { t, i18n } = useTranslation();

  const cardData = getItem("userSummary");
  const card = cardData?.find(
    (item: { card_name: any }) => item.card_name === CardNames?.ODO_MILEAGE
  );

  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
    if (getItem("currentStep") === CardNames?.ODO_MILEAGE) {
      setSubmit(false);
    }
    const userData = getItem("userDetails");
    if (
      userData?.data?.data?.user?.customerVehicles?.customerVehicleId &&
      userData?.data?.data?.user?.customerVehicles?.customerVehicleId != ""
    ) {
    }
  }, [props?.cardActive]);

  const submitData = () => {
    const userData = getItem("userDetails");
    if (inputValue.trim() === "") {
      setError("Plate number cannot be empty");
    } else {
      setError("");
      setSubmit(true);
      props.onSubmit(nextcomponent);
      setItem("currentStep", CardNames?.DEALER_LOCATION);
      manageCardData(inputValue);
      if (props.edit !== "") {
        const summary = getItem("userSummary");
        const cardNamesToRemove = [
          CardNames?.DEALER_LOCATION,
          CardNames?.REQUESTED_SERVICE,
          CardNames?.DATE_TIME,
        ];
        const filteredData = summary.filter(
          (item: { card_name: string }) =>
            !cardNamesToRemove.includes(item.card_name)
        );
        if (filteredData) {
          setItem("userSummary", filteredData);
        }
      }
      fetchCartData(
        userData?.data?.data?.user?.customerVehicles?.customerVehicleId
      );
    }
  };

  const manageCardData = (input: any) => {
    const data = {
      card_name: CardNames?.ODO_MILEAGE,
      temp: {
        odoVal: input,
      },
      title: "Vehicle mileage",
      data: [
        {
          title: "",
          sub_title: input,
        },
      ],
    };
    updateOrAddCardData("", data, "userSummary");
  };

  useEffect(() => {
    const summary = getItem("userSummary");
    const card = summary?.find(
      (item: { card_name: any }) => item.card_name === CardNames?.ODO_MILEAGE
    );
    if (card) {
      setSummaryData(card);
      if (props.edit === "") setSubmit(true);
      setinputValue(card ? card.data[0]?.sub_title : null);
    }
  }, [isSubmit]);

  const fetchCartData = (vehicleId: string) => {
    apiService
      .get(
        `${process.env.REACT_APP_AUTOMOTIVE_API_DOMAIN}/api/cart?referenceId=${vehicleId}`,
        "",
        ""
      )
      .then((response) => {
        if (response && response?.data) {
          clearcartData(response?.data.data._id);
        }
      })
      .catch((error) => {
        // handleApiError(error);
      });
  };

  const clearcartData = (cartId: string) => {
    apiService
      .delete(
        `${process.env.REACT_APP_AUTOMOTIVE_API_DOMAIN}/api/cart/${cartId}/item`
      )
      .then((response) => {
        if (response && response?.data) {
        }
      })
      .catch((error) => {
        // handleApiError(error);
      });
  };

  const formatMonth = (lastDate: any) => {
    if (lastDate) return format(new Date(lastDate), "MMMM dd, yyyy");
  };

  return (
    <>
      <CardLayout
        bg={`${isSubmit ? " border-[#E9E9E9] border" : ""} ${
          props.cardActive ? "bg-[#FAFAFA] border-0" : ""
        }`}
      >
        <div className="relative">
          <div className="cardHeader flex items-center">
            <Icon name={isSubmit ? "activeStep" : "inactiveStep"} />
            <div className="flex items-start flex-col ml-[15px]">
              <label
                className={`font-medium text-black block rtl:mr-2 ${
                  props.cardActive && props.edit === ""
                    ? "font-medium text-2xl"
                    : "text-lg"
                }`}
              >
               {t("Vehicle mileage",i18n.language)}
              </label>
              {isSubmit && summaryData && (
                <>
                  <span className="font-medium text-sm block">
                    {t('Odometer reading',i18n.language)}:{" "}
                    {inputValue ? inputValue : summaryData?.data[0]?.sub_title}
                  </span>
                  <span
                    onClick={() => {
                      props?.editPrompt(card);
                    }}
                    className={`${
                      currentStep === CardNames?.ODO_MILEAGE ? "hidden" : ""
                    } absolute  cursor-pointer top-5 rtl:left-0 ltr:right-0`}
                  >
                    <Icon name="arrowLarge" />
                  </span>
                </>
              )}
              <span
                // onClick={() => toggleCard()}
                className={`${
                  currentStep !== CardNames?.ODO_MILEAGE ? "hidden" : ""
                } absolute rtl:left-0 ltr:right-0 cursor-pointer top-5`}
              >
                <Icon name="arrowLarge" />
              </span>
            </div>
          </div>
          {!isSubmit && props?.cardActive && (
            <div className="cardBody mt-[28px] md:ml-[37px]">
              <label className="font-medium text-[15px] block mb-[7px]">
                {t("Odometer reading",i18n.language)}
              </label>
              <input
                className={`bg-white border-[1px] border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-1 max-w-[516px] ${
                  error ? "border border-red-800" : ""
                }`}
                placeholder={t("Enter your odometer reading",i18n.language)}
                value={inputValue}
                onChange={(e) => setinputValue(e.target.value)}
                type="number"
              />
              <span className="block font-medium text-xs text-muted mt-1">
                {t("Odometer reading last updated",i18n.language)}{":"}
                {
                  userDetails?.data?.data?.user?.customerVehicles?.serviceData
                    ?.lastOdometerReading?.value
                }
                {userDetails?.data?.data?.user?.customerVehicles?.serviceData
                  ?.lastOdometerReading?.value !== 0 &&
                  userDetails?.data?.data?.user?.customerVehicles?.serviceData
                    ?.lastDate &&
                  " on "}
                {userDetails?.data?.data?.user?.customerVehicles?.serviceData
                  ?.lastOdometerReading?.value !== 0 &&
                  formatMonth(
                    userDetails?.data?.data?.user?.customerVehicles?.serviceData
                      ?.lastDate
                  )}
              </span>

              {error && (
                <span className="text-red-500 text-xs block mt-1">{error}</span>
              )}
              <div className="btnArea mt-8">
                <button
                  className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                  onClick={() => submitData()}
                  disabled={!inputValue}
                >
                  {t("Continue",i18n.language)}
                </button>
              </div>
            </div>
          )}
        </div>
      </CardLayout>
    </>
  );
};

export default OdoMileage;
