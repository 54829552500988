import { useTranslation } from "react-i18next";

interface customProps {
    title: string;
    subTitle: string;
    cancelModal(): void;
    confirmModal(): void;
}

const PromptModal = (props: customProps) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="flex flex-col text-center">
                <div className="font-medium text-black text-2xl">
                    {props.title}
                </div>
                <p className="font-medium text-xs text-muted mt-3 mb-6">
                    {props.subTitle}
                </p>
                <div className="masterBtn mt-2 mb-2">
                    <button className='h-12 text-sm sm:w-40 w-28 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md mr-[10px] rtl:ml-2' onClick={props.cancelModal}> {t("Cancel",i18n.language)}</button>
                    <button className='h-12 text-sm sm:w-40 w-28 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md' onClick={props.confirmModal}>{t("Confirm",i18n.language)}</button>
                </div>
            </div>
        </>
    )
}

export default PromptModal;