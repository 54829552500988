import React from "react";
import GoogleMapReact from "google-map-react";
import Icon from "../icons/Icons";

const Marker = ({ text }: any) => (
  <div
    style={{
      color: "black",
      padding: "15px 10px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
    <Icon name="pin" />
  </div>
);

const MapComponent = ({ locations }: any) => {
  const loc = [locations];
  const defaultProps = {
    center: {
      lat: locations?.location?.latitude,
      lng: locations?.location?.longitude,
    },
    zoom: 11,
  };

  return (
    <div
      style={{
        height: "208px",
        width: "100%",
        borderRadius: "6px",
        overflow: "hidden",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDYsYZZuKSIKdx-kWnlVPdn5ipYiTj_CrE" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          text={``}
        />

        {loc?.map((location: any, index: number) => (
                    <Marker
                        key={index}
                        lat={location?.location?.latitude}
                        lng={location?.location?.longitude}
                        text={location?.title || `Marker ${index + 1}`}
                    />
                ))}
      </GoogleMapReact>
    </div>
  );
};

export default MapComponent;
