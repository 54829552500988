import React, { useState, useEffect } from "react";
import Icon from "../icons/Icons";
import { useTranslation } from "react-i18next";

interface CustomProps {
  timeSlot: {
    name: string;
    starttime: string;
    endtime: string;
    timeSlots: any;
  };
  callBack(time: string): void;
}

const TimeSelector = (props: CustomProps) => {
  const [selBrake, setSelBrake] = useState("morning");
  const [selDate, setSelDate] = useState("");
  const [timeslots, setTimeslots] = useState<
    { time: string; period: string }[]
  >([]);
  const [timeDeff, setTimeDeff] = useState<number>(15);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const generateTimeSlots = (startTimeStr: string, endTimeStr: string) => {
      const slots = [];
      let startTime = new Date(`1970-01-01T${convertTo24Hour(startTimeStr)}`);
      const endTime = new Date(`1970-01-01T${convertTo24Hour(endTimeStr)}`);

      while (startTime <= endTime) {
        const timeString = startTime.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        let period = "morning";
        const hour = startTime.getHours();

        if (hour >= 12 && hour < 17) {
          period = "afternoon";
        } else if (hour >= 17) {
          period = "evening";
        }

        slots.push({ time: timeString, period });
        startTime.setMinutes(startTime.getMinutes() + timeDeff);
      }

      return slots;
    };

    if (props.timeSlot && props.timeSlot.starttime && props.timeSlot.endtime) {
      //   const { starttime, endtime } = props.timeSlot;
      // const slots = generateTimeSlots(starttime, endtime);
      const slots = formatTimeSlots(props.timeSlot?.timeSlots);
      setTimeslots(slots);
    }
  }, [props.timeSlot, timeDeff]);

  const selectBrake = (time: string) => {
    setSelBrake(time);
  };

  const selectOption = (time: string) => {
    setSelDate(time);
    props.callBack(time);
  };
  const convertTo24Hour = (time: string) => {
    const [hours, minutes] = time.split(/[:\s]/);
    const period = time.split(" ")[1];
    let hours24 = parseInt(hours, 10);

    if (period?.toLowerCase() === "pm" && hours24 < 12) {
      hours24 += 12;
    } else if (period?.toLowerCase() === "am" && hours24 === 12) {
      hours24 = 0;
    }

    return `${hours24.toString().padStart(2, "0")}:${minutes}`;
  };
  const formatTimeSlots = (backSlots: string[]) => {
    const slots: {
      time: string;
      period: string;
    }[] = [];

    // const endTime = new Date(`1970-01-01T${convertTo24Hour(endTimeStr)}`);

    backSlots?.forEach((item, index) => {
      const startTime = new Date(
        `1970-01-01T${convertTo24Hour(backSlots?.[index])}`
      );
      const timeString = startTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      let period = "morning";
      const hour = startTime.getHours();

      if (hour >= 12 && hour < 17) {
        period = "afternoon";
      } else if (hour >= 17) {
        period = "evening";
      }

      slots.push({ time: timeString, period });
      startTime.setMinutes(startTime.getMinutes() + timeDeff);
    });

    return slots;
  };
  const filteredSlots = timeslots.filter((slot) => slot.period === selBrake);

  return (
    <>
      <div className="mt-8">
        <label className="font-medium text-[15px] text-black block mb-[18px]">
          {t("Choose time",i18n.language)}
        </label>
        <div className="calendarDiv flex gap-2">
          <div className="timeTab flex gap-10">
            <div
              className={`childTab max-md:flex-col max-md:gap-1 flex items-center gap-3 pb-2 cursor-pointer border-b-2 ${
                selBrake === "morning" ? "border-primary" : "border-white"
              }`}
              onClick={() => selectBrake("morning")}
            >
              <Icon
                name="morning"
                fillColor={selBrake === "morning" ? "#023f88" : ""}
              />
              <label
                className={`font-medium text-[13px] cursor-pointer ${
                  selBrake === "morning" ? "text-primary" : "text-muted"
                }`}
              >
                {t("Morning",i18n.language)}
              </label>
            </div>
            <div
              className={`childTab max-md:flex-col max-md:gap-1 flex items-center gap-3 pb-2 cursor-pointer border-b-2 ${
                selBrake === "afternoon" ? "border-primary" : "border-white"
              }`}
              onClick={() => selectBrake("afternoon")}
            >
              <Icon
                name="afternoon"
                fillColor={selBrake === "afternoon" ? "#023f88" : ""}
              />
              <label
                className={`font-medium text-[13px] cursor-pointer ${
                  selBrake === "afternoon" ? "text-primary" : "text-muted"
                }`}
              >
                {t("Afternoon",i18n.language)}            
              </label>
            </div>
            <div
              className={`childTab max-md:flex-col max-md:gap-1 flex items-center gap-3 pb-2 cursor-pointer border-b-2 ${
                selBrake === "evening" ? "border-primary" : "border-white"
              }`}
              onClick={() => selectBrake("evening")}
            >
              <Icon
                name="evening"
                fillColor={selBrake === "evening" ? "#023f88" : ""}
              />
              <label
                className={`font-medium text-[13px] cursor-pointer ${
                  selBrake === "evening" ? "text-primary" : "text-muted"
                }`}
              >
                {t("Evening",i18n.language)}              
              </label>
            </div>
          </div>
        </div>
        <div className="timeSlots mt-5 flex gap-[5px] flex-wrap mb-4">
          {filteredSlots && filteredSlots.length > 0 ? (
            filteredSlots.map((service, index) => (
              <div
                key={index}
                onClick={() => selectOption(service.time)}
                className={`w-[106px] h-[52px] rounded-md relative overflow-hidden cursor-pointer flex justify-center items-center flex-col ${
                  service.time === selDate
                    ? "shadow-[inset_0px_0px_0px_2px] shadow-primary"
                    : "border border-[#E7E7E7]"
                }`}
              >
                {service.time === selDate && (
                  <span className="absolute left-0 top-0">
                    <Icon name="active" />
                  </span>
                )}
                <span className="block font-medium text-sm text-muted">
                  {service.time}
                </span>
              </div>
            ))
          ) : (
            <label className="font-medium text-[13px] text-gray-400 block mb-[18px]">
              {t("No time slot available",i18n.language)}
            </label>
          )}
        </div>
      </div>
    </>
  );
};

export default TimeSelector;
