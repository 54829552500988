import React, { useEffect, useState } from "react";
import OTPInput from "./OtpInputs";
import apiService from "../../../api/api";
import Icon from "../../icons/Icons";
import CardNames from "../../../util/CardNames";
import { getItem, setItem } from "../../../util/storageService";
import { updateOrAddCardData } from "../../../util/ManageSummary";
import Success from "./Succsess";
import { navigateTo } from "../../../router/appRouter";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

interface customProps {
  data: any;
  resendOTP: () => void;
  verifyOTP: (otp: any) => void;
  reportIssue: () => void;
  flag?: string;
  manageBookingLogin?: boolean;
}

const VerifyOTP = (props: customProps) => {
  const [otp, setOtp] = useState("");
  const [validate, setValidate] = useState(true);
  const [error, setError] = useState("");
  const [otpLength, setOtpLength] = useState(6);
  const [loader, setLoader] = useState<boolean>(false);
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [clearData, setClearData] = useState<boolean>(false);
  const [timer, setTimer] = useState(60); // 60 seconds timer
  const [isTimeout, setIsTimeout] = useState(false);
  const { t, i18n } = useTranslation();


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          setIsTimeout(true);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [isSubmit, clearData]);
  useEffect(() => {
    const userData = getItem("userDetails");
    if (userData?.data?.data?.token && props?.manageBookingLogin) {
      navigateTo(`/${i18n.language}/manage-booking`);
    }
  }, []);

  useEffect(() => {
    if (clearData) {
      // Reset clearData after processing
      setClearData(false);
    }
  }, [clearData]);

  const getOtp = (otp: string) => {
    if (otp.length === otpLength) {
      setValidate(false);
      setOtp(otp);
    } else {
      setValidate(true);
      setOtp("");
    }
  };

  const VerifyOTPCode = () => {
    if (otp.trim() === "") {
      setError("OTP cannot be empty");
    } else {
      setIsBtnDisable(true);
      setLoader(true);
      const data = {
        otp: otp,
        otpToken: props?.data?.data?.token,
        scope: props?.flag,
      };
      apiService
        .post("/app/customer/verify-by-license-plate", data)
        .then((response) => {
          setLoader(false);
          setError("");
          setSubmit(true);
          props.verifyOTP(response);
          manageCardData(response?.data?.data);
        })
        .catch((error) => {
          setLoader(false);
          setError(error?.response?.data?.message);
          setIsBtnDisable(false);
        });
    }
  };

  const manageCardData = (resp: any) => {
    const data = {
      card_name: CardNames?.PLATE_NUMBER,
      temp: {
        vId: resp?.user?.customerVehicles?.customerVehicleId,
      },
      title: "Vehicle Details",
      data: [
        {
          title: resp?.user?.customerVehicles?.licensePlate,
          sub_title:
            resp?.user?.customerVehicles?.make +
            " " +
            resp?.user?.customerVehicles?.model +
            " " +
            resp?.user?.customerVehicles?.modelYear,
        },
      ],
    };
    updateOrAddCardData(resp, data, "userSummary");
  };

  return (
    <div className="flex flex-col items-center mt-6">
      <h1 className="font-medium text-2xl text-black">{`${t("Verify",i18n.language)} OTP`}</h1>
      <p className="mt-[5] text-muted text-xs max-md:text-[10px] block text-center font-medium">
        {`${t("Please enter the OTP (One-Time Password) sent to your mobile",i18n.language)}`}
        <br className="max-md:hidden" /> {`${t("registered number ending in", i18n.language)}`} {props?.data?.data?.phone}
      </p>
      <div className="w-full max-w-[516px]">
        <div className="mt-[35px] mb-2">
          <OTPInput
            clearData={clearData}
            error={!!error}
            inputsLength={otpLength}
            onSubmit={getOtp}
          />
        </div>
        {error && (
          <div className="text-[#EB5757] font-medium text-[11px]">{error}</div>
        )}
        <label className="block text-center pt-3 mb-5 font-medium text-xs text-black">
          {t("Resend OTP in", i18n.language)}
          {timer > 0 && (
            <span className="text-primary inline-block ml-1 rtl:mr-2">00:{timer}</span>
          )}
          {timer === 0 && (
            <span
              onClick={() => {
                props.resendOTP();
                setClearData(true); // Trigger clearing of OTP input
                setError("");
                setTimer(60); // reset timer
                setSubmit(false); // Reset submission status
                setIsTimeout(false); // Reset timeout status
                setIsBtnDisable(false); // Enable button again
              }}
              className="text-primary cursor-pointer inline-block ml-1"
            >
            {t("Resend", i18n.language)}
            </span>
          )}
        </label>
        <div className="text-center mb-8 flex items-center justify-center w-full">
          <button
            type="submit"
            onClick={VerifyOTPCode}
            className="h-12 text-sm flex items-center justify-center w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
            disabled={validate || isBtnDisable}
          >
            {!loader ? <span>{t("Verify",i18n.language)}</span> : <Icon width={25} name="spinner" />}
          </button>
        </div>
      </div>
      <div className="mb-11">
        <span className="block text-muted text-center font-medium text-xs">
          {t("If you did not receive SMS then your vehicle may be registered with another mobile number",i18n.language)}
        </span>
        <div className="text-center flex gap-2 mt-2 items-center w-full justify-center">
          <a href="tel:966-920002999" className="text-muted font-medium text-xs">
            Call: <strong className="text-black">966-920002999</strong>
          </a>
          <span
            className="text-muted font-medium text-xs underline cursor-pointer"
            onClick={() => {
              props?.reportIssue();
            }}
          >
            {t("Report an Issue",i18n.language)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
