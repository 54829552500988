// src/AppRouter.tsx
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../App';
import Landing from '../pages/Landing';
import BookServicePage from '../pages/BookService';
import ManageService from '../pages/ManageService';
import PreviewBooking from '../pages/PreviewBooking';
import ManageBooking from '../pages/ManageBooking';
import CancelBooking from '../pages/CancelPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/:lng/*",
        element: <App />,
        children: [
            {
                path: "",
                element: <Landing />,
            },
            {
                path: "book-a-service",
                element: <BookServicePage />,
            },
            {
                path: "preview-booking",
                element: <PreviewBooking />,
            },
            {
                path: "manage-service",
                element: <ManageService />,
            },
            {
                path: "manage-booking",
                element: <ManageBooking />,
            },
            {
                path: "booking-canceled",
                element: <CancelBooking />,
            }
        ],
    },
]);

export const navigateTo = (path: string) => {
    router.navigate(path);
};

const AppRouter: React.FC = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
