import leftimg from "../../assets/temp/left.png";
import rightimg from "../../assets/temp/right.png";
import lincoln from "../../assets/temp/lincoln.svg";
import i18n from "../../i18n";
import { navigateTo } from "../../router/appRouter";
import { getItem } from "../../util/storageService";
import LangSelector from "./LangSelector";

const Header = () => {
  const goToHome = () => {
    navigateTo(`/${i18n.language}`);
  };

  return (
    <header className="header">
      <div className="flex flex-col pt-2.5 w-full bg-white max-lg:max-w-full">
        <div className="flex gap-5 justify-between self-center w-full max-w-[1300px] max-lg:flex-wrap max-lg:max-w-full px-[15px]">
          <div className="flex items-center justify-between sm:gap-10 gap-5">
            <img
              onClick={() => goToHome()}
              loading="lazy"
              src={leftimg}
              className="shrink-0 my-auto w-32 max-lg:h-10 max-lg:w-20 max-lg:object-contain cursor-pointer"
            />

            {/* <img
              onClick={() => goToHome()}
              loading="lazy"
              src={lincoln}
              className="shrink-0 my-auto w-32 max-lg:h-10 max-lg:w-20 max-lg:object-contain cursor-pointer"
            /> */}
          </div>

          <div className="flex items-center justify-between sm:gap-12 gap-5">
            <span className="flex items-center justify-between">
              <LangSelector />
            </span>

            <img
              loading="lazy"
              src={rightimg}
              className="h-[92px] w-auto max-lg:h-10 max-lg:w-10 max-lg:object-contain"
            />
          </div>
        </div>
      </div>
      <div className="mt-2.5 w-full bg-neutral-200 min-h-[1px]" />
    </header>
  );
};

export default Header;
