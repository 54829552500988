import { getItem, setItem } from "./storageService";

const updateOrAddCardData = (resp: any, data: any, storageKey: string) => {
    const existingData = getItem(storageKey);
    if (!existingData) {
        const d = [data];
        setItem(storageKey, d);
    }
    const index = existingData.findIndex((item: { card_name: any; }) => item.card_name === data.card_name);
    if (index !== -1) {
        // Update existing card
        existingData[index] = data;
    } else {
        // Add new card
        existingData.push(data);
    }
    // localstorege set item
    setItem(storageKey, existingData);
};

export { updateOrAddCardData };
