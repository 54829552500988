import { useEffect, useState } from "react";
import Icon from "../components/icons/Icons";
import { navigateTo } from "../router/appRouter";
import { useTranslation } from "react-i18next";
import { getItem, setItem } from "../util/storageService";

const Landing = () => {
  const [type, setType] = useState("");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const hasLocalStorageValues = localStorage.length > 0; // Check if localStorage has any values

    if (hasLocalStorageValues) {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, "", window.location.href);
      };
    }

    return () => {
      window.onpopstate = null;
    };
  }, []);

  const selectOption = (type: string) => {
    setType(type);
    const lang = getItem("language");
    localStorage.clear();
    if (!lang) setItem("language", "en");
    else setItem("language",lang)
  };

  const getStart = () => {
    if (type === "book") {
      navigateTo(`/${i18n.language}/book-a-service`);
    }
    if (type === "manage") {
      navigateTo(`/${i18n.language}/manage-service`);
      setItem("userDetails", "");
    }
  };

  return (
    <div className="flex flex-col m-auto sm:mt-[94px] mb-[118px] max-sm:mb-10 self-center w-full max-w-[1077px] max-lg:max-w-full">
      <div className="self-stretch max-lg:max-w-full">
        <div className="flex gap-[100px] max-lg:flex-col max-lg:gap-0">
          <div className="flex flex-col w-[55%] max-lg:ml-0 max-lg:w-full">
            <div className="flex flex-col self-stretch my-auto font-medium max-lg:mt-10 max-lg:max-w-full">
              <div className="text-5xl text-primary leading-[54px] max-lg:max-w-full max-lg:text-4xl max-lg:leading-[50px] font-medium">
                {t("Effortless service booking at Al Jazirah", i18n.language)}
              </div>
              <div className="mt-9 max-lg:mt-4 text-base leading-7 text-muted max-lg:max-w-full">
                {t(
                  "Effortlessly schedule new service appointments or update existing ones to keep your vehicle in top condition",
                  i18n.language
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col  w-[45%] max-lg:ml-0 max-lg:w-full">
            <div className="text-xs font-medium uppercase mb-4 text-muted max-lg:mt-10">
              {t("Choose an Option", i18n.language)}
            </div>
            <div className="flex flex-col grow font-medium gap-5">
              <div
                onClick={() => selectOption("book")}
                className={`flex flex-col overflow-hidden items-start pt-[30px] pb-[25px] pr-20 pl-8 bg-white rounded-md  border-solid border-[1px] border-neutral-200 max-lg:px-5 cursor-pointer relative hover:shadow-[inset_0px_0px_0px_2px] hover:shadow-primary  box-border transition-[border_0.5]  ${
                  type === "book"
                    ? "shadow-[inset_0px_0px_0px_2px] shadow-primary"
                    : ""
                }`}
              >
                {type === "book" && (
                  <span className="absolute left-0 top-0">
                    <Icon name="active" />
                  </span>
                )}
                <Icon name="book" />
                <div className="mt-5 text-lg leading-5 text-black">
                  {t("Book a Service", i18n.language)}
                </div>
                <div className="mt-2 text-sm leading-6 text-zinc-600">
                  {t("Schedule Your Appointment Now", i18n.language)}
                </div>
              </div>
              <div
                onClick={() => selectOption("manage")}
                className={`flex flex-col overflow-hidden items-start pt-[30px] pb-[25px] pr-20 pl-8 bg-white rounded-md  border-solid border-[1px] border-neutral-200 max-lg:px-5 cursor-pointer relative hover:shadow-[inset_0px_0px_0px_2px] hover:shadow-primary  box-border transition-[border_0.5]  ${
                  type === "manage"
                    ? "shadow-[inset_0px_0px_0px_2px] shadow-primary"
                    : ""
                }`}
              >
                {type === "manage" && (
                  <span className="absolute left-0 top-0">
                    <Icon name="active" />
                  </span>
                )}
                <Icon name="manage" />
                <div className="mt-5 text-lg leading-5 text-black">
                  {t("Manage an Existing Service", i18n.language)}
                </div>
                <div className="mt-2 text-sm leading-6 text-zinc-600">
                  {t(
                    "Update or Review Your Current Appointments",
                    i18n.language
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-right mt-[30px]">
          <button
            disabled={type === ""}
            onClick={() => getStart()}
            className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
          >
            {t("Get Started", i18n.language)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
