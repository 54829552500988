import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';
import { initReactI18next } from 'react-i18next';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

const langPathDetector:LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: (cb: (arg0: string) => any) => cb(window.location.pathname.split('/')[1] || 'en'),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.use(langPathDetector)
  .init({
    resources,
    fallbackLng: 'en', // Fallback language if translation is not available
    keySeparator: false, // No key separator
    interpolation: {
      escapeValue: false, // React handles escaping
      formatSeparator: ',',
    },
    react: {
      useSuspense: true, // Use Suspense for translations
    },
    debug: true, // Debug mode
  });

export default i18n;
