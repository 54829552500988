import { useEffect, useState } from "react";
import Icon from "../icons/Icons";
import CardLayout from "../common/Card";
import CardNames from "../../util/CardNames";
import BookingConfirmed from "../misc/BookingConfirmed";
import Modal from "../modal";
import PromptModal from "../modal/child/PromptModal";
import CancelModal from "../modal/child/CancelModal";
import moment from "moment-timezone";
import { navigateTo } from "../../router/appRouter";
import i18n from "../../i18n";
import { getItem, setItem } from "../../util/storageService";
import { useTranslation } from "react-i18next";

interface customProps {
  cardActive: boolean;
  onSubmit: (nextcomponent: string) => void;
  data?: any;
}

const ManageBookingCard = (props: customProps) => {
  const [nextcomponent, setSetcomponent] = useState(CardNames?.ODO_MILEAGE);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [cancelBook, setcancelBook] = useState<boolean>(false);
  const [cancelWithReason, setcancelWithReason] = useState<boolean>(false);
  const [reschedule, setreschedule] = useState<boolean>(false);
  const searchBy = getItem("search_by");
  const searchedPlateNumber = getItem("plate_number_manage");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
  }, [props?.cardActive]);

  const submitData = () => {
    setSubmit(true);
    props.onSubmit(nextcomponent);
  };
  const showMoreData = () => {
    setViewMore(!viewMore);
  };

  const cancelBooking = () => {
    setcancelWithReason(true);
  };
  const closeModal = () => {
    setcancelBook(false);
    setcancelWithReason(false);
    setreschedule(false);
  };
  const confirmModal = () => {
    if (reschedule) {
      setItem("isReschedule", true);
      setItem("cancelId", props?.data?._id);
      navigateTo(`/${i18n.language}/book-a-service`);
    }
    closeModal();
  };
  const confirmReason = () => {
    closeModal();
  };
  const rescheduleBooking = () => {
    setreschedule(true);
  };

  return (
    <>
      {reschedule && (
        <Modal
          closeIcon={true}
          closeModal={closeModal}
          customClass={"max-w-[540px] md:!max-w-[540px]"}
          backButton={() => {}}
        >
          <PromptModal
            title={t("Reschedule", i18n.language)}
            subTitle={t(
              "Are you sure you want to reschedule your booking?",
              i18n.language
            )}
            cancelModal={closeModal}
            confirmModal={confirmModal}
          />
        </Modal>
      )}

      {cancelWithReason && (
        <Modal
          closeIcon={true}
          closeModal={closeModal}
          customClass={"max-w-[540px] md:!max-w-[540px]"}
          backButton={() => {}}
        >
          <CancelModal
            data={props?.data}
            title={t("Cancel Booking", i18n.language)}
            subTitle={t(
              "Are you sure you want to cancel your booking?",
              i18n.language
            )}
            cancelModal={closeModal}
            confirmModal={confirmReason}
          />
        </Modal>
      )}
      <CardLayout bg={isSubmit ? "bg-[#FCFCFC]" : ""}>
        <div className="relative">
          <div className="flex items-start flex-col w-full">
            <div className="flex items-center justify-start w-full gap-3">
              <label className="font-medium text-2xl text-primary block">
                {props?.data?.currentOdometerReading?.value}{" "}
                {t("KM Service", i18n.language)}
              </label>
              <div className="flex items-center justify-between cursor-pointer">
                <span
                  className={`font-medium text-[10px] px-[9px] py-[2px] rounded inline-block uppercase 
                                            ${
                                              props?.data?.status ===
                                              "cancelled"
                                                ? "bg-red-700"
                                                : ""
                                            } 
                                            ${
                                              props?.data?.status === "pending"
                                                ? "bg-orange-500"
                                                : ""
                                            } 
                                            ${
                                              props?.data?.status ===
                                              "scheduled"
                                                ? "bg-gray-500"
                                                : ""
                                            } 
                                            ${
                                              props?.data?.status === "ongoing"
                                                ? "bg-blue-500"
                                                : ""
                                            } 
                                            ${
                                              props?.data?.status ===
                                              "completed"
                                                ? "bg-green-500"
                                                : ""
                                            } 
                                            ${
                                              props?.data?.status ===
                                              "awaitingparts"
                                                ? "bg-yellow-500"
                                                : ""
                                            } 
                                            text-white capitalize`}
                >
                  {props?.data?.status}
                </span>
              </div>
            </div>
            <div className="addressDiv mt-[8px] w-full">
              <div className="flex flex-row items-center">
                <span className="text-xs font-medium block  text-muted text-left">
                  {searchBy == "plateNumber" || searchedPlateNumber
                    ? `${t("Plate Number", i18n.language)}`
                    : `${t("VIN Number", i18n.language)}`}
                </span>
                <span className="text-sm font-medium block text-black ml-1">
                  {searchBy == "plateNumber" || searchedPlateNumber
                    ? props?.data?.customerVehicle?.licensePlate
                    : props?.data?.customerVehicle?.vin}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-start flex-col w-full mt-4">
            <div className="addressDiv mt-[8px] w-full flex gap-5 justify-between">
              <div className="flex flex-col items-start mb-3 gap-[2px]">
                <span className="text-xs font-medium block text-muted text-left">
                  {t("WIP Number", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black">
                  {props?.data?.wipNumber}
                </span>
              </div>
              <div className="flex flex-col items-start mb-3 gap-[2px]">
                <span className="text-xs font-medium block text-muted text-left">
                  {t("Service Date", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black">
                  {moment(props?.data?.appointmentDateAndTime)
                    .tz("Asia/Riyadh")
                    .format("MMM DD, YYYY [at] hh:mm a")}
                </span>
              </div>
              <div className="flex flex-col items-start mb-3 gap-[2px]">
                <span className="text-xs font-medium block text-muted text-left">
                  {t("Service Center", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black">
                  {props?.data?.serviceCenter?.title}
                </span>
              </div>
            </div>
          </div>

          {viewMore && (
            <div className="detailsData">
              <div className="pt-3 pb-3">
                <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
              </div>
              <div className="flex items-start flex-col w-full mt-4">
                <div className="flex items-center justify-between w-full">
                  <label className="font-medium text-lg text-primary block">
                    {t("Details", i18n.language)}
                  </label>
                </div>
                <div className="addressDiv mt-[8px] w-full">
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Name", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerDetails?.fullName}
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Mobile", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerDetails?.primaryPhone}
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Email Address", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerDetails?.primaryEmail}
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Address", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {
                        props?.data?.customerVehicle?.relations[2]?.customer
                          ?.address
                      }
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Brand", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerVehicle?.make}
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Model Year", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerVehicle?.modelYear}
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Model", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerVehicle?.model}
                    </span>
                  </div>
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Registration Number", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerVehicle?.licensePlate}
                    </span>
                  </div>
                  <div className="flex flex-row items-center max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("VIN", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.customerVehicle?.vin}
                    </span>
                  </div>
                  <div className="pt-6 pb-6">
                    <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-start flex-col w-full">
                <div className="flex items-center justify-between w-full">
                  <label className="font-medium text-lg text-primary block">
                    {t("Vehicle mileage", i18n.language)}
                  </label>
                  {["scheduled"].includes(props?.data?.status) && (
                    <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                      <Icon name="edit" />
                      <span className="font-medium text-xs text-muted">
                        {t("Edit", i18n.language)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="addressDiv mt-[8px] w-full">
                  <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                    <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                      {t("Odometer reading", i18n.language)}
                    </span>
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.currentOdometerReading?.value} km
                    </span>
                  </div>

                  <div className="pt-6 pb-6">
                    <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-start flex-col w-full">
                <div className="flex items-center justify-between w-full">
                  <label className="font-medium text-lg text-primary block">
                    {t("Dealership Location", i18n.language)}
                  </label>
                  {["scheduled"].includes(props?.data?.status) && (
                    <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                      <Icon name="edit" />
                      <span className="font-medium text-xs text-muted">
                        {t("Edit", i18n.language)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="addressDiv mt-[8px] w-full">
                  <div className="flex flex-row items-center mb-3">
                    <span className="text-sm font-medium block text-black">
                      {props?.data?.serviceCenter?.address?.address} {" - "}
                      {props?.data?.serviceCenter?.address?.country}
                    </span>
                  </div>

                  <div className="pt-6 pb-6">
                    <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-start flex-col w-full">
                <div className="flex items-center justify-between w-full">
                  <label className="font-medium text-lg text-primary block">
                    {t("Service details", i18n.language)}
                  </label>
                  {["scheduled"].includes(props?.data?.status) && (
                    <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                      <Icon name="edit" />
                      <span className="font-medium text-xs text-muted">
                        {t("Edit", i18n.language)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="addressDiv mt-[8px] w-full">
                  {props?.data?.products?.map((product: any, index: any) => (
                    <div>
                      <div className="flex flex-row items-center mb-3">
                        <span className="text-xs font-medium block  text-muted text-left">
                          {product?.serviceTypeDetails?.name}
                        </span>
                      </div>
                      <div className="flex flex-row items-center mb-3">
                        <span className="text-sm font-medium block text-black">
                          {product?.name}
                        </span>
                      </div>
                    </div>
                  ))}

                  <div className="pt-6 pb-6">
                    <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-start flex-col w-full">
                <div className="flex items-center justify-between w-full">
                  <label className="font-medium text-lg text-primary block">
                    {t("Date and Time", i18n.language)}
                  </label>
                  {["scheduled"].includes(props?.data?.status) && (
                    <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                      <Icon name="edit" />
                      <span className="font-medium text-xs text-muted">
                        {t("Edit", i18n.language)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="addressDiv mt-[8px] w-full">
                  <div className="flex flex-row items-center mb-3">
                    <span className="text-sm font-medium block text-black">
                      {moment(props?.data?.appointmentDateAndTime)
                        .tz("Asia/Riyadh")
                        .format("MMM DD, YYYY [at] hh:mm a")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="viewDetailTag cursor-pointer flex items-center gap-2 mt-3"
            onClick={showMoreData}
          >
            <label className="font-medium text-primary text-sm cursor-pointer">
              {viewMore
                ? t("View Less Details", i18n.language)
                : t("View More Details", i18n.language)}
            </label>
            <span className={`transition-all ${viewMore ? "" : "rotate-180"}`}>
              <Icon name="arrow" />
            </span>
          </div>

          {["scheduled"].includes(props?.data?.status) && (
            <>
              <div className="pt-6 pb-6">
                <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
              </div>

              <div className="cardDataMasterBtn max-sm:flex items-center justify-between flex-col gap-4 mt-2 mb-2">
                <button
                  className="h-12 text-sm sm:w-40 w-full disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md mr-[10px] rtl:ml-3"
                  onClick={() => cancelBooking()}
                >
                  {t("Cancel Booking", i18n.language)}
                </button>
                <button
                  className="h-12 text-sm sm:w-40 w-full disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                  onClick={() => rescheduleBooking()}
                >
                  {t("Reschedule", i18n.language)}
                </button>
              </div>
            </>
          )}
        </div>
      </CardLayout>
    </>
  );
};

export default ManageBookingCard;
