import React, { useEffect, useState } from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import DefaultLayout from './layout/DefaultLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css'
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import { clearStorage } from './util/storageService';
import { navigateTo } from './router/appRouter';
import Modal from './components/modal';
import SessionExpired from './components/modal/child/SessionExpired';

function App() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isIdle, setIsIdle] = useState(false);

  const onIdle = () => {
    setIsIdle(true);
    toast.warn('You have been idle for 2 hours. Your session will expire.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleOnAction = () => {
    if (isIdle) {
      setIsIdle(false);
    }
  };

  const handleOnActive = () => {
    if (isIdle) {
      setIsIdle(false);
      clearStorage();
      navigateTo(`/${i18n.language}/`);
    }
  };

  const toHome = () => {
    clearStorage();
    navigateTo(`/${i18n.language}/`);
  };



  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/${i18n.language}`, { replace: true });
    }
    if (i18n.language === "ar") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [location, navigate, i18n.language]);

  return (
    <IdleTimerProvider
      timeout={2 * 60 * 60 * 1000} // 2 hours in milliseconds
      onIdle={onIdle}
      onAction={handleOnAction}
      onActive={handleOnActive}
      debounce={250}
    >
      <div className="App">
        <DefaultLayout>
          <Outlet />
          <ToastContainer />
          {isIdle && (
            <Modal closeIcon={false} backButton={()=>{}} closeModal={() => setIsIdle(true)} customClass={'!max-w-[540px]'}>
              <SessionExpired confirmModal={() => toHome()} />
            </Modal>
          )}
        </DefaultLayout>
      </div>
    </IdleTimerProvider>
  );
}

export default App;
