import axios from "axios";
import axiosInstance from "./instance";
import { getItem } from "../util/storageService";

const apiService = {
  get: (url: string, params?: any, type?: string) => {
    if (type === "cart") {
      const userDetail: any = getItem("userDetails");
      const token = userDetail?.data?.data?.token;
      const lang = getItem("language");
      const customAxiosInstance = axios.create({
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          language: lang ? lang : "en",
        },
      });
      return customAxiosInstance.get(url, { params });
    }
    return axiosInstance.get(url, { params });
  },
  post: (url: string, data?: any, type?: string) => {
    if (type === "cart") {
      const userDetail: any = getItem("userDetails");
      const token = userDetail?.data?.data?.token;
      const lang = getItem("language");
      const customAxiosInstance = axios.create({
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          language: lang ? lang : "en",
        },
      });
      return customAxiosInstance.post(url, data);
    }
    return axiosInstance.post(url, data);
  },
  put: (url: string, data: any) => {
    return axiosInstance.put(url, data);
  },
  delete: (url: string, id?: number) => {
    return axiosInstance.delete(url);
  },
  patch: (url: string, data: any) => {
    return axiosInstance.patch(url, data);
  },
};

export default apiService;
