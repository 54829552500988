import React, { useState, useEffect, useRef } from 'react';

interface CustomProps {
  inputsLength: number;
  customClass?: string;
  onSubmit(val: string): void;
  clearData?: boolean;
  error?: any;
}

const OTPInput: React.FC<CustomProps> = ({ inputsLength, customClass, onSubmit, clearData, error }) => {
  const [otp, setOtp] = useState<string[]>(Array(inputsLength).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (element: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = element.target.value;
  
    // Validate and update OTP array
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
  
      // Jump to next input field
      if (value && index < inputsLength - 1) {
        inputsRef.current[index + 1]?.focus();
      }
  
      onSubmit(newOtp.join(""));
    } else {
      // Clear the input field in case of invalid input
      element.target.value = '';
    }
  };
  


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    inputsRef.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (clearData) {
      setOtp(Array(inputsLength).fill(''));
      inputsRef.current[0]?.focus();
    }
  }, [clearData, inputsLength]);

  return (
    <div className={`otp-input-container flex justify-between gap-3 md:gap-1`}>
  {Array.from({ length: inputsLength }, (_, index) => (
    <input
      key={index}
      type="text"
      maxLength={1}
      value={otp[index] || ''} // Default to empty string if undefined
      onChange={(e) => handleChange(e, index)} // Pass the event and index
      onKeyDown={(e) => handleKeyDown(e, index)} // Handle key down if needed
      ref={(ref) => (inputsRef.current[index] = ref)}
      className={`${customClass || ''} ${otp[index] ? 'border-2 border-black' : 'border-none'} w-full h-[58px] sm:w-[68px] sm:h-[68px] rounded-md text-center flex items-center justify-center font-medium text-lg text-black bg-[#F6F6F6] ${error ? 'border-[1px] outline-none !border-[#EB5757]' : ''}`}
    />
  ))}
</div>
  );
};

export default OTPInput;
