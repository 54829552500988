const CardNames = {
    CAR_DETAILS: "cardetail",
    PLATE_NUMBER: "platenumber",
    ODO_MILEAGE: "odomileage",
    DEALER_LOCATION: "dealerlocation",
    REQUESTED_SERVICE:"requestedservice",
    DATE_TIME:"datetime"
};

export default CardNames;
