import { ChangeEvent, useState } from "react";

interface customProps {
    submit: (val: string) => void;
}

const NotesModal = (props: customProps) => {

    const [value, setValue] = useState<string>('');
    const [isSubmit, setSubmit] = useState<boolean>(true);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        props.submit(value);
    };
    const handleChange = (element: ChangeEvent<HTMLInputElement>) => {
        const value = element.target.value;
        if(value.length>0){
            setSubmit(false);
            setValue(value);
        }
        else{
            setValue('');
            setSubmit(true);
        }
        
    }

    return (
        <div className="flex flex-col items-start mt-6">
            <h1 className="font-medium text-2xl text-black">Check Engine Fail safe mode / wrench lights on</h1>
            <p className="mt-[10px] text-muted text-sm block text-left font-medium">
                Add notes on the selected issues so that we will have a better understanding
            </p>
            <form onSubmit={handleSubmit} className="w-full">
                <div className='mt-[26px] mb-[22px]'>
                    <textarea className="bg-[#F6F6F6] rounded-md h-[110px] py-[14px] px-[25px] w-full" placeholder="Add your note" onChange={(e: any) => handleChange(e)}></textarea>
                </div>
                
                <div className='text-left mb-9'>
                    <button type='submit' disabled={isSubmit} onClick={(e: any) => handleSubmit(e)} className='h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md'>Verify</button>
                </div>
            </form>
        </div>
    )
}

export default NotesModal;