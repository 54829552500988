import { useEffect, useState } from "react";
import ManageBookingCard from "../components/cards/ManageBookingCard";
import { getItem } from "../util/storageService";
import { navigateTo } from "../router/appRouter";
import i18n from "../i18n";
import FullLoader from "../components/common/Loader";
import { useTranslation } from "react-i18next";

const ManageBooking = () => {
  const [booingStatus, setBookingStatus] = useState(false);
  const [userFullData, setUserData] = useState<any>();
  const [loader, setLoader] = useState(true);
  const [showNoBookingModal, setShowNoBookingModal] = useState(false);
  const { t, i18n } = useTranslation();

  const handleComponent = (nextcomponent: string) => {};
  const closeModal = () => {
    setShowNoBookingModal(false);
    navigateTo(`/${i18n.language}`);
  };
  useEffect(() => {
    const userData = getItem("userDetails");
    setUserData(userData?.data.data);
    setLoader(false);

    if (userData?.data.data.token) {
      navigateTo(`/${i18n.language}/manage-booking`);
    }

    // Check if there are no bookings and show the modal
    const noBookings =
      !userData?.data?.data?.bookings?.upcoming?.length &&
      !userData?.data?.data?.bookings?.inProgress?.length &&
      !userData?.data?.data?.bookings?.pending?.length &&
      !userData?.data?.data?.bookings?.completed?.length &&
      !userData?.data?.data?.bookings?.cancelled?.length;

    if (noBookings) {
      setShowNoBookingModal(true);
      
    }
  }, [i18n.language]);

  useEffect(() => {
    const step = getItem("currentStep");
    const userData = getItem("userDetails");
    const userSummary = getItem("userSummary");
    const plateNumber =
      userData?.data?.data?.user?.customerVehicles?.licensePlate;
    setUserData(userData?.data.data);
    setLoader(false);
    if (userData?.data.data.token) {
      navigateTo(`/${i18n.language}/manage-booking`);
    }
  }, []);
  return (
    <div className="max-w-[830px] mx-auto relative mt-10 mb-[50px] px-4">
      {loader && (
        <FullLoader
          fullLoader={true}
          iconColor="#023f88"
          class="w-[100px] text-primary"
        />
      )}
      <div className="w-full">
        <h2 className="font-medium text-[22px] text-black mb-7">
          {t("Manage an Existing Service", i18n.language)}
        </h2>
        <div className="w-full">
          <>
            {userFullData?.bookings?.upcoming?.map((data: any, index: any) => (
              <ManageBookingCard
                key={index}
                data={data}
                cardActive={true}
                onSubmit={handleComponent}
              />
            ))}

            {userFullData?.bookings?.inProgress?.map(
              (data: any, index: any) => (
                <ManageBookingCard
                  key={index}
                  data={data}
                  cardActive={true}
                  onSubmit={handleComponent}
                />
              )
            )}
            {userFullData?.bookings?.pending?.map((data: any, index: any) => (
              <ManageBookingCard
                key={index}
                data={data}
                cardActive={true}
                onSubmit={handleComponent}
              />
            ))}
            {userFullData?.bookings?.completed?.map((data: any, index: any) => (
              <ManageBookingCard
                key={index}
                data={data}
                cardActive={true}
                onSubmit={handleComponent}
              />
            ))}
            {userFullData?.bookings?.cancelled?.map((data: any, index: any) => (
              <ManageBookingCard
                key={index}
                data={data}
                cardActive={true}
                onSubmit={handleComponent}
              />
            ))}
          </>
        </div>
        {showNoBookingModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-[500px] text-center">
              <h2 className="text-2xl font-medium text-black mb-4">
                {t("No Bookings Found", i18n.language)}
              </h2>
              <p className="text-sm text-gray-500 mb-6">
                {t(
                  "It seems like you don't have any bookings. Please make a booking to view it here.",
                  i18n.language
                )}
              </p>
              <button
                onClick={closeModal}
                className="bg-primary text-white px-6 py-2 rounded-md"
              >
                {t("Go Home", i18n.language)}
              </button>
            </div>
          </div>
        )}

        {booingStatus && (
          <div className="masterBtn mt-8">
            <button className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md mr-[10px]">
              {t("Go Home", i18n.language)}
            </button>
            <button className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md">
              {t("Add to Calendar", i18n.language)}
            </button>
            <button className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md">
              {t("View or Manage Booking", i18n.language)}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageBooking;
