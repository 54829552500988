import { useEffect, useState } from "react";
import Icon from "../icons/Icons";
import { Item, ServiceObject } from "../../type/Service";
import { getItem } from "../../util/storageService";
import i18n from "../../i18n";
import { t } from "i18next";

interface customProps {
  activeTab: boolean;
  toggleTab(): void;
  addNotes(): void;
  product?: any;
  selectedData: (selectedServices: any, type: string) => void;
  selectedOptions: any;
}

const InspectionCheckList = (props: customProps) => {
  const [tabActive, setTabActive] = useState(props.activeTab);

  //   const [selectedServices, setSelecteditems] = useState<any>([]);
  //   const [selItems, setSelItem] = useState<any>();
  //   const [items, setItems] = useState<any[]>([]);

  const tabToggle = () => {
    setTabActive(!tabActive);
  };

  //   const setItemsData = (item: any) => {
  //     // setSelItem(item);
  //     if (item) {
  //       setItems((prevItems) => {
  //         const itemExists = prevItems.some(
  //           (existingItem) => existingItem._id === item._id
  //         );
  //         if (itemExists) {
  //           return prevItems.filter(
  //             (existingItem) => existingItem._id !== item._id
  //           );
  //         } else {
  //           return [...prevItems, item];
  //         }
  //       });
  //     }
  //   };

  const selectOption = (item: any, type: string) => {
    props?.selectedData(item, type);
    // setSelecteditems(item);
    // setItemsData(item);
  };
  const selectedItemsCount = () => {
    const items = props?.selectedOptions?.filter(
      (item: any) => item?.categoryDetails?.code === props?.product?.code
    );
    return items?.length > 0
      ? `${items.length} ${t("Issue(s) Selected", i18n.language)}`
      : t("No issues Selected", i18n.language);
  };

  const isChecked = (val: any) => {
    return !!props?.selectedOptions?.find(
      (item: any) => item?.name === val?.name
    );
  };

  // Log selectedServices whenever it changes
  // useEffect(() => {
  // }, [items]);

  // const addNotes = () => {
  //     props.addNotes()
  // }

  return (
    <>
      <div>
        <div
          className="flex flex-row justify-between items-start cursor-pointer"
          onClick={props.toggleTab}
        >
          <div className="flex flex-col text-left">
            <label className="font-medium text-[15px] block mb-[7px]">
              {i18n.language === "ar"
                ? props?.product?.arabicName
                : props?.product?.name}{" "}
              ({props.product?.services?.length})
            </label>
            {/* {props?.product?.code !== "routine" && ( */}
            <span className="block font-medium text-xs">
              {/* {props?.selectedOptions?.length > 0
                ? props?.selectedOptions?.length + " Issues(s) Selected"
                : "No Issues Selected"} */}
              {selectedItemsCount()}
            </span>
            {/* )} */}
          </div>
          <span
            className={`transition-all ${
              tabActive ? "rotate-0" : "rotate-180"
            }`}
          >
            <Icon name="arrow" />
          </span>
        </div>
        {props?.activeTab && (
          <div className="kmBox pt-[25px]">
            <div className="kmBoxList flex flex-wrap gap-[5px] w-full">
              <div className="flex w-full">
                <ul className="w-full">
                  {props.product?.services?.map((item: Item, index: number) => (
                    <li key={index} className="mb-5 last:mb-0 w-full">
                      <div className="flex flex-row items-center justify-between gap-3">
                        <div className="flex flex-row items-center  gap-3">
                          <input
                            onChange={() => {
                              selectOption(
                                item,
                                props?.product?.code === "routine"
                                  ? "radio"
                                  : "checkbox"
                              );
                            }}
                            id={item._id}
                            name={`${
                              props?.product?.code === "routine"
                                ? "radio"
                                : item._id
                            }`}
                            // type={`${
                            //   props?.product?.code === "routine"
                            //     ? "radio"
                            //     : "checkbox"
                            // }`}
                            type="checkbox"
                            checked={isChecked(item)}
                            className="cursor-pointer accent-primary overflow-hidden w-[22px] h-[22px] rounded-[5px] border-2 border-[#E9E9E9]"
                          />

                          <label
                            htmlFor={item?._id}
                            className="font-medium text-black cursor-pointer text-sm"
                          >
                            {i18n.language === "ar"
                              ? item?.arabicName || item?.name
                              : item?.name || item?.code}
                          </label>
                        </div>
                        <div className="flex items-center cursor-pointer gap-2 flex-row">
                          {/* <Icon name="edit" />
                                                    <span className="text-muted font-medium text-xs" onClick={() => addNotes()}>Add Note</span> */}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="pt-[30px] pb-6">
          <div className="h-[1px] bg-[#E7E7E7] w-full"></div>
        </div>
      </div>
    </>
  );
};

export default InspectionCheckList;
