// src/api/api.js
import axios from "axios";
import handleApiError from "../util/ErrorService";
import { getItem } from "../util/storageService";

const API_BASE_URL = process.env.REACT_APP_API_DOMAIN;
const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Optional: Add interceptors for request and response handling
axiosInstance.interceptors.request.use(
  (config) => {
    // Add token or any custom headers here
    const userDetail: any = getItem("userDetails");
    const token = userDetail?.data?.data?.token;
    const lang = getItem("language");
    config.headers.language = lang ? lang : "en";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally here
    if (error.response && error.response.status === 401) {
      handleApiError(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
