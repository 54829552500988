import { useEffect, useState } from "react";
import Icon from "../icons/Icons";
import CardLayout from "../common/Card";
import CardNames from "../../util/CardNames";
import BookingConfirmed from "../misc/BookingConfirmed";
import { getItem, setItem } from "../../util/storageService";
import { UserData } from "../../type/UserDetails";
import { navigateTo } from "../../router/appRouter";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

interface customProps {
  cardActive: boolean;
  onSubmit: (nextcomponent: string) => void;
  bookingSubmited: boolean;
  bookingdata: any;
}

const PreviewCard = (props: customProps) => {
  const [nextcomponent, setSetcomponent] = useState(CardNames?.ODO_MILEAGE);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [bookingConfirmed, setbookingConfirmed] = useState<boolean>(false);
  const userDetails: any = getItem("userDetails");
  const userData = userDetails?.data?.data?.user;
  const vData = userDetails?.data?.data?.user?.customerVehicles;
  const step = getItem("currentStep");
  const summary = getItem("userSummary");
  const odoMeterReading = summary?.find(
    (item: { card_name: string }) => item.card_name === CardNames?.ODO_MILEAGE
  );
  const serviceLocation = summary?.find(
    (item: { card_name: string }) =>
      item.card_name === CardNames?.DEALER_LOCATION
  );
  const reqService = summary?.find(
    (item: { card_name: string }) =>
      item.card_name === CardNames?.REQUESTED_SERVICE
  );
  const plateNumber = summary?.find(
    (item: { card_name: string }) => item.card_name === CardNames?.PLATE_NUMBER
  );
  const dateTime = summary?.find(
    (item: { card_name: string }) => item.card_name === CardNames?.DATE_TIME
  );
  const { t } = useTranslation();
  if (userDetails?.length < 5) {
    navigateTo(`/${i18n.language}/book-a-service`);
  }
  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
    if (props?.bookingSubmited) {
      setItem("languageHider", "yes");
      setbookingConfirmed(true);
    }
  }, [props?.cardActive, props?.bookingSubmited]);
  const submitData = () => {
    setSubmit(true);
    props.onSubmit(nextcomponent);
  };
  const editCard = (card: any) => {
    setItem("currentStep", card?.card_name);
    setSetcomponent(card?.card_name);
    navigateTo(`/${i18n.language}/book-a-service`);
  };
  return (
    <>
      <CardLayout bg={isSubmit ? "bg-[#FCFCFC]" : ""}>
        <div className="relative">
          {bookingConfirmed && (
            <div className="confirmBox">
              <BookingConfirmed
                title={t("Booking Confirmed", i18n.language)}
                wip={props?.bookingdata?.data.data.wipNumber}
                text={t(
                  "Thank you for booking your service with us!",
                  i18n.language
                )}
              />
            </div>
          )}
          <div className="flex items-start flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <label className="font-medium text-lg text-primary block">
                {t("Vehicle Details", i18n.language)}
              </label>
            </div>
            <div className="addressDiv mt-[8px] w-full">
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Plate Number", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {plateNumber?.data[0]?.title}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Name", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {userData?.fullName}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Mobile", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {userData?.phones[0]?.phone}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Email Address", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black">
                  {userData?.primaryEmail?.toLowerCase()}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Address", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {userData?.addresses[0]?.line1 +
                    userData?.addresses[0]?.street +
                    userData?.addresses[0]?.city +
                    userData?.addresses[0]?.country +
                    userData?.addresses[0]?.zip}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Brand", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {vData?.make}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Model Year", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {vData?.modelYear}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Model", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {vData?.model}
                </span>
              </div>
              <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Registration Number", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {vData?.licensePlate}
                </span>
              </div>
              <div className="flex flex-row items-center max-md:flex-col max-md:items-start">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("VIN", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black break-all">
                  {vData?.vin}
                </span>
              </div>
              <div className="pt-6 pb-6">
                <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <label className="font-medium text-lg text-primary block">
                {t("Vehicle mileage", i18n.language)}
              </label>
              {!bookingConfirmed && (
                <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                  <Icon name="edit" fillColor="#023F88" />
                  <span className="font-medium text-xs text-primary">
                    {t("Edit", i18n.language)}
                  </span>
                </div>
              )}
            </div>
            <div className="addressDiv mt-[8px] w-full">
              <div className="flex flex-row items-center mb-3">
                <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
                  {t("Odometer reading", i18n.language)}
                </span>
                <span className="text-sm font-medium block text-black">
                  {odoMeterReading?.data[0]?.sub_title} km
                </span>
              </div>

              <div className="pt-6 pb-6">
                <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <label className="font-medium text-lg text-primary block">
                {t("Service Center", i18n.language)}
              </label>
              {!bookingConfirmed && (
                <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                  <Icon name="edit" fillColor="#023F88" />
                  <span className="font-medium text-xs text-primary">
                    {t("Edit", i18n.language)}
                  </span>
                </div>
              )}
            </div>
            <div className="addressDiv mt-[8px] w-full">
              <div className="flex flex-row items-center mb-3">
                <span className="text-sm font-medium block text-black">
                  {serviceLocation?.data[0]?.sub_title}
                </span>
              </div>

              <div className="pt-6 pb-6">
                <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <label className="font-medium text-lg text-primary block">
                {t("Service details", i18n.language)}
              </label>
              {!bookingConfirmed && (
                <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                  <Icon name="edit" fillColor="#023F88" />
                  <span className="font-medium text-xs text-primary">
                    {t("Edit", i18n.language)}
                  </span>
                </div>
              )}
            </div>
            <div className="addressDiv mt-[8px] w-full">
              <div className="icBody flex flex-col items-start justify-start mt-[22px]">
                {reqService?.data.map((item: any, itemIndex: number) => (
                  <div key={itemIndex} className="flex flex-col gap-[12px]">
                    {item.title && (
                      <span className="font-medium text-black text-sm block">
                        {item.title}
                      </span>
                    )}
                    <span className="font-medium text-black text-sm block">
                      {item.sub_title}
                    </span>
                  </div>
                ))}
              </div>
              <div className="pt-6 pb-6">
                <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <label className="font-medium text-lg text-primary block">
                {t("Date and Time", i18n.language)}
              </label>
              {!bookingConfirmed && (
                <div className="hidden  items-center justify-between gap-1 cursor-pointer">
                  <Icon name="edit" fillColor="#023F88" />
                  <span className="font-medium text-xs text-primary">
                    {t("Edit", i18n.language)}
                  </span>
                </div>
              )}
            </div>
            <div className="addressDiv mt-[8px] w-full">
              <div className="flex flex-row items-center mb-3">
                <span className="text-sm font-medium block text-black">
                  {dateTime?.data[0]?.title}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CardLayout>
    </>
  );
};

export default PreviewCard;
