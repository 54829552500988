import { useEffect, useState } from "react";
import Select from "react-select";
import apiService from "../../../api/api";
import FullLoader from "../../common/Loader";
import { getItem, setItem } from "../../../util/storageService";
import { UserData } from "../../../type/UserDetails";
import handleApiError from "../../../util/ErrorService";
import { navigateTo } from "../../../router/appRouter";
import i18n from "../../../i18n";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

interface customProps {
  title: string;
  subTitle: string;
  cancelModal(): void;
  confirmModal(): void;
  data?: any;
}

const CancelModal = (props: customProps) => {
  const [error, setError] = useState("");
  const [reason, setReason] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [reasonDta, setReasonData] = useState<any>();
  const { t, i18n } = useTranslation();

  const options = [
    { value: "ChangeofPlans", label: "Change of Plans" },
    { value: "Datechange", label: "Date Change" },
  ];
  const isReschedule = getItem("isReschedule") || false;
  const getSelectValue = (e: any) => {
    setReason(e);
    // /app/customer/cancel-booking/[booking id]
  };

  useEffect(() => {
    getReasons();
  }, []);

  const submitData = () => {
    setLoader(true);
    const userDetails: UserData = getItem("userDetails");
    const cancelId = props?.data?._id;
    const data = {
      cancellationReasonId: reason?.value || reasonDta[0]?.value,
      cancellationComment: "",
      customerId: userDetails?.data?.data?.userId,
    };
    apiService
      .post(`/app/customer/cancel-booking/${cancelId}`, data)
      .then((response) => {
        setLoader(false);
        setError("");
        props.confirmModal();
        if (!isReschedule) {
          setItem("cancel_wip_number", props?.data?.wipNumber);
          navigateTo(`/${i18n.language}/booking-canceled`);
        }
      })
      .catch((error) => {
        setLoader(false);
        setError(error?.response?.data?.message);
        handleApiError(error);
      });
  };

  const getReasons = () => {
    // TODO: Set this on page translation button click
    // setItem("language", "en");
    
    apiService
      .get("/app/customer/service-cancellation-reasons")
      .then((response) => {
        const respdata = response?.data?.data;
        const optionData = respdata.map((respdata: any) => ({
          value: respdata._id,
          label:
          i18n.language === "ar" ? respdata?.arabicName : respdata.name,
        }));
        setReasonData(optionData);
      })
      .catch((error) => {
        // handleApiError(error);
      });
  };

  return (
    <>
      {loader && (
        <FullLoader
          fullLoader={false}
          iconColor="#023f88"
          class="w-[100px] text-primary"
        />
      )}
      <div className="flex flex-col text-center">
        <div className="font-medium text-black text-2xl">{props.title}</div>
        <p className="font-medium text-xs text-muted mt-3 mb-3">
          {props.subTitle}
        </p>
        <div className="pt-3 pb-3">
          <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
        </div>
        <div className="addressDiv mt-[8px] w-full">
          <div className="flex flex-row items-center mb-3">
            <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
              {t("WIP Number", i18n.language)}
            </span>
            <span className="text-sm font-medium block text-black">
              {props?.data?.wipNumber}
            </span>
          </div>
          <div className="flex flex-row items-center mb-3">
            <span className="text-xs font-medium block w-[180px] text-muted text-left rtl:text-right">
              {t("Service Date", i18n.language)}
            </span>
            <span className="text-sm font-medium block text-black">
              {moment(props?.data?.appointmentDateAndTime)
                .tz("Asia/Riyadh")
                .format("MMM DD, YYYY [at] hh:mm a")}
            </span>
          </div>
        </div>
        {!isReschedule && (
          <div className="mb-4 flex items-start flex-col mt-3 w-full">
            <label className="font-medium text-black text-xs block mb-2">
              {t("Reason for Cancellation", i18n.language)}
            </label>
            <div className="w-full text-left">
              <Select
                classNamePrefix="reactSelect"
                className={`customSelect pt-[8px] bg-white  rounded-md w-full h-[54px] placeholder:font-medium border-[1px] placeholder:text-sm text-sm text-black font-medium placeholder:text-[#979A9C] px-[15px] outline-none max-w-[516px] ${
                  error ? "border border-red-800" : ""
                }`}
                options={reasonDta}
                placeholder={t("Choose your reason", i18n.language)}
                onChange={getSelectValue}
              />
            </div>
          </div>
        )}

        <div className="masterBtn mt-4 mb-2">
          <button
            className="h-12 text-sm sm:w-40 w-28 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md mr-[10px] rtl:ml-2"
            onClick={props.cancelModal}
          >
            {t("Cancel", i18n.language)}
          </button>
          <button
            className="h-12 text-sm sm:w-40 w-28 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
            disabled={!isReschedule && reason?.length === 0}
            onClick={() => submitData()}
          >
            {t("Confirm", i18n.language)}
          </button>
        </div>
      </div>
    </>
  );
};

export default CancelModal;
