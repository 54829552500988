import { useEffect, useState } from "react";
import Icon from "../icons/Icons";
import CardLayout from "../common/Card";
import CardNames from "../../util/CardNames";
import Select from "react-select";
import LocationList from "../misc/DealerLocationList";
import apiService from "../../api/api";
import { getItem, setItem } from "../../util/storageService";
import { updateOrAddCardData } from "../../util/ManageSummary";
import { useTranslation } from "react-i18next";

interface customProps {
  cardActive: boolean;
  onSubmit: (nextcomponent: string) => void;
  edit?: string;
  editPrompt(card: any): void;
}

const DealerLocation = (props: customProps) => {
  const [nextcomponent, setSetcomponent] = useState(
    CardNames?.REQUESTED_SERVICE
  );
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const [inputValue, setinputValue] = useState<any>();
  const [error, setError] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<any>();

  const [city, setcity] = useState<any>([]);
  const [center, setCenter] = useState<any>([]);
  const [summaryData, setSummaryData] = useState<any>();

  const userDetail = getItem("userDetails");
  const token = userDetail?.data?.data?.token;
  const currentSteplocal = getItem("currentStep");
  const [currentStep, setCurrentStep] = useState(currentSteplocal);

  const { t, i18n } = useTranslation();

  const cardData = getItem("userSummary");
  const card = cardData?.find(
    (item: { card_name: any }) => item.card_name === CardNames?.DEALER_LOCATION
  );

  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
    const currentStep = getItem("currentStep");
    setCurrentStep(currentStep);
  }, [props?.cardActive, inputValue, selectedLocation]);

  useEffect(() => {
    const summary = getItem("userSummary");
    const card = summary?.find(
      (item: { card_name: any }) =>
        item.card_name === CardNames?.DEALER_LOCATION
    );
    if (card) {
      if (props.edit === "") setSubmit(true);
      checkLocal();
    }
  }, [isSubmit]);

  useEffect(() => {
    if (currentStep === CardNames?.DEALER_LOCATION) {
      getCity();
    }
  }, [currentStep]);

  useEffect(() => {
    if (props?.cardActive) {
      if (currentStep === CardNames?.DEALER_LOCATION) {
        if (props.edit === "") {
          setSubmit(true);
        }
        if (card) {
          checkLocal();
        }
      }
    }
  }, [props?.cardActive]);

  const checkLocal = () => {
    setSummaryData(card);
    setinputValue(card?.temp?.inputValue);
    getCenter(card?.temp?.inputValue?.value, () => {
      setSelectedLocation(card?.temp?.location);
    });
  };

  useEffect(() => {
    if (props?.cardActive) {
      checkLocal();
    }
  }, [isSubmit]);

  const getCity = () => {
    
    if (city.length == 0) {
      apiService
        .get("/app/customer/cities")
        .then((response) => {
          const respdata = response?.data?.data;
          const cityOptions = respdata.map((respdata: any) => ({
            value: respdata._id,
            label:
              i18n.language === "ar" ? respdata.arabicName : respdata.name,
          }));
          setcity(cityOptions);
        })
        .catch((error) => {
          // handleApiError(error);
        });
    }
  };

  const getCenter = (locationid: string, onSuccess?: any) => {
    apiService
      .get("/app/customer/service-centers", { cityId: locationid })
      .then((response) => {
        setCenter(response?.data?.data);
        if (onSuccess) {
          onSuccess(response?.data?.data);
        }
      })
      .catch((error) => {
        // handleApiError(error);
      });
  };

  const submitData = () => {
    if (!inputValue) {
      setError("Please select a location");
    } else {
      setError("");
      setSubmit(true);
      props.onSubmit(nextcomponent);
      setItem("currentStep", CardNames?.REQUESTED_SERVICE);
      manageCardData();
      if (props.edit !== "") {
        const summary = getItem("userSummary");
        const cardNamesToRemove = [
          CardNames?.REQUESTED_SERVICE,
          CardNames?.DATE_TIME,
        ];
        const filteredData = summary.filter(
          (item: { card_name: string }) =>
            !cardNamesToRemove.includes(item.card_name)
        );
        if (filteredData) {
          setItem("userSummary", filteredData);
        }
      }
    }
  };

  const manageCardData = () => {
    const data = {
      card_name: CardNames?.DEALER_LOCATION,
      title: "Service Center",
      temp: {
        inputValue,
        location: selectedLocation,
        selectedLocation: selectedLocation?._id,
      },
      data: [
        {
          title: "",
          sub_title:
            selectedLocation?.address?.city + " - " + selectedLocation?.title,
        },
        {
          title: "",
          sub_title:
            selectedLocation?.address?.address +
            " " +
            selectedLocation?.address?.city +
            " " +
            selectedLocation?.address?.country +
            " " +
            selectedLocation?.phone,
        },
      ],
    };
    updateOrAddCardData("", data, "userSummary");
  };

  const getSelectValue = (e: any) => {
    setSelectedLocation("");
    getCenter(e?.value);
    setinputValue(e);
  };

  const getCenterTiming = (payload: any) => {
    setSelectedLocation(payload);
  };

  return (
    <>
      <CardLayout
        bg={`${isSubmit ? " border-[#E9E9E9] border" : ""} ${
          props.cardActive ? "bg-[#FAFAFA] border-0" : ""
        }`}
      >
        <div className="relative">
          <div className="cardHeader flex items-center">
            <Icon name={isSubmit ? "activeStep" : "inactiveStep"} />
            <div className="flex items-start flex-col ml-[15px]">
              <label className="font-medium text-lg text-blac}k block rtl:mr-2">
                {t("Service Center", i18n.language)}
              </label>
              {isSubmit && summaryData && (
                <>
                  <span className="font-medium text-sm block">
                    {inputValue
                      ? inputValue?.label
                      : summaryData?.data[0]?.sub_title}
                  </span>
                  <span
                    onClick={() => {
                      props?.editPrompt(card);
                    }}
                    className={`${
                      currentStep === CardNames?.DEALER_LOCATION ? "hidden" : ""
                    } absolute rtl:left-0 ltr:right-0 cursor-pointer top-5`}
                  >
                    <Icon name="arrowLarge" />
                  </span>
                </>
              )}
            </div>
          </div>
          {currentStep === CardNames?.DEALER_LOCATION && (
            <div className="cardBody mt-[28px] md:ml-[37px]">
              <label className="font-medium text-[15px] block mb-[7px]">
                {t("Choose City", i18n.language)}
              </label>
              <Select
                classNamePrefix="reactSelect"
                className={`customSelect pt-[8px] bg-white border-[1px]
                                     border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] outline-none max-w-[516px] ${
                                       error ? "border border-red-800" : ""
                                     }`}
                options={city}
                value={inputValue}
                placeholder={t("Choose your city", i18n.language)}
                onChange={(e) => getSelectValue(e)}
              />

              {inputValue && (
                <div className="locationList mt-[25px]">
                  <span className="font-medium text-xs text-muted mb-[10px] block">
                    {center?.length} {t("service center’s on", i18n.language)}{" "}
                    {inputValue?.label}
                  </span>
                  <LocationList
                    location={center}
                    onSubmit={(selectedLocation) => {
                      {
                        getCenterTiming(selectedLocation);
                      }
                    }}
                    selectedValue={selectedLocation}
                  />
                </div>
              )}

              {error && (
                <span className="text-red-500 text-xs block mt-1">{error}</span>
              )}
              <div className="btnArea mt-8">
                <button
                  className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                  onClick={() => submitData()}
                  disabled={!selectedLocation}
                >
                  {t("Continue", i18n.language)}
                </button>
              </div>
            </div>
          )}
        </div>
      </CardLayout>
    </>
  );
};

export default DealerLocation;
