import { t } from "i18next";
import i18n from "../../i18n";
import Icon from "../icons/Icons";

// Function to handle URL redirection based on language
const getLocalizedUrl = (urlEn: string, urlAr: string): string => {
  return i18n.language === "ar" ? urlAr : urlEn;
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="flex gap-5 px-16 py-9 max-lg:py-[20px] w-full text-sm font-medium leading-5 bg-blue-950 max-lg:flex-wrap max-lg:px-5  max-lg:max-w-full">
        <div className="flex-1 gap-[66px] justify-start max-lg:justify-center text-center text-white flex max-lg:hidden">
          <div>
            <a
              href={getLocalizedUrl(
                "https://en.aljazirahford.com/",
                "https://ar.aljazirahford.com/"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Back to Website", i18n.language)}
            </a>
          </div>
          <div>
            <a
              href={getLocalizedUrl(
                "https://en.aljazirahford.com/site/privacy-and-legal/",
                "https://ar.aljazirahford.com/site/privacy-and-legal/"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Terms and Conditions", i18n.language)}
            </a>
          </div>
        </div>
        <div className="flex flex-1 gap-[42px] justify-end text-center text-white max-lg:justify-center max-lg:border-b-[1px] max-lg:border-[#3D437A] max-lg:pb-[20px]">
          <div className="flex gap-2 items-center">
            <Icon name="location" />
            <a
              href={getLocalizedUrl(
                "https://en.aljazirahford.com/locations/",
                "https://ar.aljazirahford.com/locations/"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Our Locations", i18n.language)}
            </a>
          </div>
          <div className="flex gap-2.5 items-center">
            <Icon name="call" />
            <a href="tel:920002999" className="my-auto font-medium text-sm">
              966920002999
            </a>
          </div>
        </div>
        <a
          href={getLocalizedUrl(
            "https://en.aljazirahford.com/site/privacy-and-legal/",
            "https://ar.aljazirahford.com/site/privacy-and-legal/"
          )}
          className="text-center text-white font-medium text-[15px] w-full hidden max-lg:block"
        >
          {t("Terms and Conditions", i18n.language)}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
