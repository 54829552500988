import React, { useEffect, useState } from "react";
import Icon from "../icons/Icons";
import { useTranslation } from "react-i18next";
import apiService from "../../api/api";
import GoogleMapReact from "google-map-react";
import MapComponent from "./MapComponent";
import { getItem } from "../../util/storageService";

interface customProps {
  location: any;
  onSubmit: (selectedLocation: any) => void;
  selectedValue?: any;
}

const LocationList = (props: customProps) => {
  const [locations, setLocation] = useState<any>([]);
  const [timeList, setTimeList] = useState<any>([]);
  const [selectedType, setSelectedType] = useState(null);
  const [seleactedLocation, setseleactedLocation] = useState<any>([]);

  const [type, setType] = useState<any>();
  const { t, i18n } = useTranslation();

  


  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  useEffect(() => {
    if (selectedType !== props?.selectedValue?._id) {
      setSelectedType(props?.selectedValue?._id);
      getTimeings(props?.selectedValue);
      setseleactedLocation(props?.selectedValue);
    }
  }, [props?.selectedValue]);

  const selectOption = (location: any, index: any) => {
    setSelectedType(index);
    props.onSubmit(location);
    getTimeings(location);
    setseleactedLocation(location);
  };

  const getTimeings = (payload: any) => {
    if (payload?._id) {
      apiService
        .get(`/app/customer/service-centers/${payload?._id}/timing`)
        .then((response) => {
          const openingHours = transformOpeningHours(response?.data?.data);
          setTimeList(openingHours);
        })
        .catch((error) => {
          // handleApiError(error);
        });
    }
  };
  const transformOpeningHours = (data: any) => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    return days.map((day) => {
      const hours = data[day];
      if (hours.length === 0) {
        return {
          day: t(day, i18n.language),
          closed: true,
          time: t("Closed", i18n.language),
        };
      }
      const startTime =
        i18n.language === "ar"
          ? hours[0].start.replace("am", "صباحاً").replace("pm", "مساءً")
          : hours[0].start;
      const endTime =
      i18n.language === "ar"
          ? hours[0].end.replace("am", "صباحاً").replace("pm", "مساءً")
          : hours[0].end;

      const time = `${startTime} - ${endTime}`;
      return { day: t(day, i18n.language), closed: false, time };
    });
  };

  return (
    <div>
      {props?.location?.map((location: any, index: number) => (
        <div key={index} className="loclist">
          <div
            onClick={() => selectOption(location, location._id)}
            className={`flex relative items-start flex-col rounded-md py-4 px-5 pb-[26px] overflow-hidden mb-[10px] cursor-pointer ${
              location._id === selectedType
                ? "border-2 border-primary"
                : "bg-white border-[1px] border-[#D0D0D0] "
            }`}
          >
            {location._id === selectedType && (
              <span className="absolute left-0 top-0">
                <Icon name="active" />
              </span>
            )}
            {i18n.language === "ar" ? (
              <>
                <label className="font-medium block text-base text-black">
                  {location.arabicTitle}
                </label>
                <span className="font-medium text-xs block text-muted mt-[6px]">
                  {location.address.addressArabic},{" "}
                  {location.address.cityArabic},{" "}
                  {location.address.countryArabic}
                </span>
              </>
            ) : (
              <>
                <label className="font-medium block text-base text-black">
                  {location.title}
                </label>
                <span className="font-medium text-xs block text-muted mt-[6px]">
                  {location.address.address}, {location.address.city},{" "}
                  {location.address.country}
                </span>
              </>
            )}

            <div className="flex font-medium flex-row items-center text-sm text-black gap-1 mt-[10px]">
              <Icon name="callsm" />
              {location.phone}
            </div>
          </div>
          {location._id === selectedType && (
            <>
              <div className="loDetail mt-6 mb-8">
                <label className="font-medium text-sm block text-black mb-[18px]">
                  {t("Opening hours", i18n.language)}
                </label>
                <div className="addressDiv mt-[17px]">
                  {timeList?.map((hour: any, idx: number) => (
                    <div key={idx} className="flex flex-row items-center mb-3">
                      <span className="text-sm text-muted font-medium block w-[180px] text-left capitalize rtl:text-right">
                        {hour.day}
                      </span>
                      <span
                        className={`text-sm font-medium block ${
                          hour.closed ? "text-[#EB5757]" : "text-black"
                        }`}
                      >
                        {hour.time}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="locMap w-full  mb-7">
                <MapComponent locations={seleactedLocation} />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default LocationList;
