import { Bounce, toast } from "react-toastify";
import { clearStorage } from "./storageService";

const handleApiError = (error: any) => {
  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
    if (message === "Unauthorized") {
      clearStorage();
      //window.location.reload();
    }
  };

  notify(
    error?.response?.data?.data?.message
      ? error?.response?.data?.data?.message
      : error?.message
  );

  // if (error.response && error.response.status === 401) {
  //     clearStorage();
  //     // window.location.reload();
  // } else {
  //     console.error('An unexpected error occurred:', error);
  // }
};

export default handleApiError;
