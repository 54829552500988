import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getItem, setItem } from '../../util/storageService';

const languages = [
    { code: "en", name: 'ENGLISH' },
    { code: "ar", name: 'ARABIC' }
];

const LangSelector = () => {
    const [activeLang, setActiveLang] = useState('en'); // Default language
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentLangFromURL = extractLangFromURL(location.pathname);
        const storedLang = getItem('language');

        if (!storedLang || storedLang === "") {
            setItem("language", activeLang);
        }

        if (storedLang !== currentLangFromURL) {
            setActiveLang(currentLangFromURL);
            setItem("language", currentLangFromURL);
        } else {
            setActiveLang(storedLang);
        }
    }, [activeLang, location.pathname]);

    const extractLangFromURL = (path: string) => {
        const langCode = path.split('/')[1]; // First part of URL path, e.g. /en or /ar
        return languages.find(lang => lang.code === langCode) ? langCode : 'en'; // Default to 'en'
    };

    const handleLangChange = (code: string) => {
        setActiveLang(code);
        setItem("language", code);
        setDropdownVisible(false);

        const currentPath = location.pathname;

        // Replace the language code in the URL
        let newPath;
        if (currentPath.startsWith('/en') || currentPath.startsWith('/ar')) {
            newPath = `/${code}${currentPath.substring(3)}`;
        } else {
            newPath = `/${code}${currentPath}`;
        }

        navigate(newPath);
        window.location.reload();
    };

    return (
        <div
            className="flex gap-3 items-center justify-between cursor-pointer relative py-2"
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
        >
            

            {/* Dropdown menu */}

            <div className="flex items-center justify-between">
                <a
                    className={`block  py-2 cursor-pointer uppercase ${activeLang === 'ar' ? 'hidden' : ''}`}
                    onClick={() => handleLangChange('ar')}
                >
                    ع
                </a>
                <a
                    className={`block  py-2 cursor-pointer uppercase ${activeLang === 'en' ? 'hidden' : ''}`}
                    onClick={() => handleLangChange('en')}
                >
                    En
                </a>
            </div>

        </div>
    );
};

export default LangSelector;
