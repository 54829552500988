import { useEffect, useState } from "react";
import CarDetails from "../components/cards/CarDetails";
import PlateNumber from "../components/cards/PlateNumber";
import CardNames from "../util/CardNames";
import OdoMileage from "../components/cards/OdoMileage";
import DealerLocation from "../components/cards/DealerLocation";
import RequestedService from "../components/cards/RequestedService";
import ChoodeDateTime from "../components/cards/ChooseDateTime";
import {
  setItem,
  getItem,
  clearSpecificItems,
  clearStorage,
} from "../util/storageService";
import Icon from "../components/icons/Icons";
import { navigateTo } from "../router/appRouter";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const BookServicePage = () => {
  const [component, setSetcomponent] = useState(CardNames?.PLATE_NUMBER);
  const [summary, setSummary] = useState<any>([]);
  const [summaryMdl, setSummaryMdl] = useState<boolean>(false);
  const [canEdit, setEdit] = useState<string>("");
  const [masterSubmit, setmasterSubmit] = useState<boolean>(true);
  const plateNumber = getItem("plate_number_manage");
  const vin = getItem("vin_number_manage");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    const handlePopState = () => {
      if (localStorage.length === 0) {
        navigateTo(`/${i18n.language}/`);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      
      window.removeEventListener("popstate", handlePopState);
    };
  }, [i18n.language]);

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "currentStep") {
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const step = getItem("currentStep");
    const userData = getItem("userDetails");
    const userSummary = getItem("userSummary");
    setSummary(userSummary);
    const plateNumber =
      userData?.data?.data?.user?.customerVehicles?.licensePlate;
    if (plateNumber) {
      // setSetcomponent(step);
    }
    if (step === "last") {
      setmasterSubmit(false);
    } else {
      setmasterSubmit(true);
    }
  }, [component, canEdit]);

  const handleComponent = (nextcomponent: string) => {
    setSetcomponent(nextcomponent);
    if (nextcomponent === "last") {
      setmasterSubmit(false);
    } else {
      setmasterSubmit(true);
    }
  };
  const openMdl = () => {
    setSummaryMdl(!summaryMdl);
  };
  const toHome = () => {
    // clearStorage();
    navigateTo(`/${i18n.language}/`);
  };

  const editCard = (card: any) => {
    setItem("currentStep", card?.card_name);
    setSetcomponent(card?.card_name);
    setEdit(card?.card_name);
  };

  const submitMaster = () => {
    const step = getItem("currentStep");
    if (step === "last") {
      navigateTo(`/${i18n.language}/preview-booking`);
    }
  };
  return (
    <div className="max-w-[1300px] mx-auto relative mt-[36px] mb-[50px] px-[15px] max-md:px-0">
      <h2 className="font-medium text-[22px] text-black mb-7">
       {t("Book a Service", i18n.language)}                 
      </h2>
      <div className="flex flex-row gap-[40px]">
        <div className="w-full flex flex-col max-w-[812px]">
          <div className="w-full">
            <PlateNumber
              editPrompt={(card) => editCard(card)}
              flag={"manage-bookings"}
              edit={canEdit}
              cardActive={component === CardNames?.PLATE_NUMBER}
              onSubmit={handleComponent}
              search={plateNumber ? { plateNumber: plateNumber } : { vin: vin }}
            />
            {/* < edit={canEdit} CarDetails cardActive={component === CardNames?.CAR_DETAILS} onSubmit={handleComponent} /> */}
            <OdoMileage
              editPrompt={(card) => editCard(card)}
              edit={canEdit}
              cardActive={component === CardNames?.ODO_MILEAGE}
              onSubmit={handleComponent}
            />
            <DealerLocation
              editPrompt={(card) => editCard(card)}
              edit={canEdit}
              cardActive={component === CardNames?.DEALER_LOCATION}
              onSubmit={handleComponent}
            />
            <RequestedService
              editPrompt={(card) => editCard(card)}
              edit={canEdit}
              cardActive={component === CardNames?.REQUESTED_SERVICE}
              onSubmit={handleComponent}
            />
            <ChoodeDateTime
              editPrompt={(card) => editCard(card)}
              edit={canEdit}
              cardActive={component === CardNames?.DATE_TIME}
              onSubmit={handleComponent}
            />
          </div>
          <div className="masterBtn mt-8 flex gap-4">
            <button
              className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
              disabled={masterSubmit}
              onClick={() => submitMaster()}
            >
              {t("Review Booking",i18n.language)}
            </button>
            <button
              className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md"
              onClick={() => toHome()}
            >
              {t("Go Home",i18n.language)}
            </button>
          </div>
        </div>
        <div
          className={`w-[435px] flex flex-col max-lg:fixed max-lg:w-full max-lg:z-10 max-lg:bottom-0 max-lg:left-0 max-lg:right-0 
        ${
          summaryMdl
            ? "before:bg-black/40 before:fixed before:w-full before:h-screen before:top-0 before:-z-[1]"
            : ""
        }`}
        >
          <div className="bg-[#FAFAFA] p-[22px] lg:rounded-xl max-lg:rounded-ss-[12px] max-lg:rounded-se-[12px] overflow-hidden  max-lg:max-h-[416px] max-lg:overflow-y-auto">
            <h2
              onClick={openMdl}
              className={`font-medium text-[20px] max-lg:text-sm max-lg:flex max-lg:justify-between max-lg:items-center uppercase block text-center lg:border-b-[1px] lg:border-[#D0D0D0] lg:pb-[18px] ${
                summaryMdl ? "border-b-[1px] border-[#D0D0D0] pb-[18px]" : ""
              }`}
            >
              {t("APPOINTMENT SUMMARY", i18n.language)}              
              <span
                className={`lg:hidden inline-block cursor-pointer transition-all ${
                  summaryMdl ? "rotate-180" : "rotate-0"
                }`}
              >
                <Icon name="arrow" />
              </span>
            </h2>

            <div className={`${summaryMdl ? "block" : "max-lg:hidden"}`}>
              <div className="transition-all">
                {summary &&
                  summary.map((card: any, cardIndex: number) => (
                    <div
                      key={cardIndex}
                      className="infoCards mt-8 border-b-[1px] border-[#D0D0D0] pb-[30px]"
                    >
                      <div className="incHead flex items-center justify-between">
                        <span className="font-medium text-primary text-base">
                          {t(card.title,i18n.language)}
                        </span>
                        {card.card_name !== CardNames?.PLATE_NUMBER && (
                          <span
                            className="flex items-center justify-end gap-1 cursor-pointer"
                            onClick={() => editCard(card)}
                          >
                            <Icon name="edit" fillColor="#023F88" />
                            <span className="font-medium text-primary text-sm">
                              {t("Edit",i18n.language)}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="icBody flex flex-col items-start justify-start mt-[22px]">
                        {card?.data.map((item: any, itemIndex: number) => (
                          <div
                            key={itemIndex}
                            className="flex flex-col gap-[12px]"
                          >
                            {item.title && (
                              <span className="font-medium text-black text-sm block">
                                {item.title}
                              </span>
                            )}
                            <span className="font-medium text-black text-sm block">
                              {item.sub_title}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="infoCards mt-8 pb-2">
                <div className="incHead flex items-center justify-between">
                  <span className="font-medium text-primary text-base">
                   {t("Total Cost", i18n.language)}                    
                  </span>
                </div>
                <div className="icBody flex flex-col items-start justify-start gap-[12px] mt-1">
                  <span className="font-medium text-muted text-[11px] block">
                    {t("To be determined after service completion at the service center", i18n.language)}                    
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookServicePage;
