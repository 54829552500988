// src/index.tsx
import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import * as ReactDOM from "react-dom/client";
import AppRouter from './router/appRouter';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
