import Footer from "../components/common/Footer";
import Header from "../components/common/Header";

const DefaultLayout = ({ children }: any) => {
    return (
        <div className="default-layout min-h-screen flex flex-col">
            <Header />
            <main className="content flex-grow lg:px-[10px] max-lg:px-[15px]">
                {children}
            </main>
            <Footer />
        </div>
    );
}

export default DefaultLayout;
