import { SetStateAction, useEffect, useState } from "react";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import Icon from "../../icons/Icons";
import Success from "./Succsess";
import Modal from "..";
import FullLoader from "../../common/Loader";
import handleApiError from "../../../util/ErrorService";
import { useTranslation } from "react-i18next";

interface customProps {
  cancelModal(): void;
  backButtonAction?: boolean;
  plateData: any;
  reportIssueSuccess(): void;
}

const ReportIssue = (props: customProps) => {
  const [error, setError] = useState("");
  const [prefix, setPrefix] = useState("");
  const [ownertype, setOwnerType] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [link, setLink] = useState("REGISTEREDKEEPER");
  const [vinNumber, setVinNumber] = useState("");
  const [reason, setReason] = useState("");
  const [ccode, setCcode] = useState<any>();
  const [next, setNext] = useState<boolean>(false);
  const [reportSubmit, setreportSubmit] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [loader, setloader] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const prefixOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Mx", label: "Mx" },
    { value: "Rev", label: "Rev" },
  ];
  const ownerType = [
    { value: "Myself", label: t("Myself", i18n.language) },
    { value: "Company", label: t("Company", i18n.language) },
  ];

  useEffect(() => {
    if (props?.backButtonAction) {
      setNext(false);
    }
  }, [props?.backButtonAction]);

  const handleNext = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (mobileNumber && mobileNumber.length !== 12) {
      setError("Invalid Phone Number");
      return;
    }
    if (email && !emailRegex.test(email)) {
      setError("Invalid email address");
      return;
    }

    if (
      !prefix ||
      !fullName ||
      !mobileNumber ||
      !registrationNumber ||
      !reason ||
      !vinNumber
    ) {
      setError("All fields except email are required.");
      return;
    }
    setError("");
    setNext(true);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", prefix);
    formData.append("customerName", fullName);
    formData.append("phoneNumber", mobileNumber);
    formData.append("licensePlate", registrationNumber);
    formData.append("requestReason", reason);
    formData.append("ownershipType", link);
    formData.append("registeredOwner", ownertype);
    if (email) {
      formData.append("email", email);
    }

    formData.append("vin", vinNumber);

    if (file) {
      formData.append("file", file);
    }

    try {
      setloader(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/api/app/customer/report-issue`,
        {
          method: "POST",
          headers: {
            Origin: `${process.env.FRONTEND_URL}`,
            Referer: `${process.env.FRONTEND_URL}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          t("Reporting issue is failed. please try again", i18n.language)
        );
      }

      const data = await response.json();
      setloader(false);
      if (data) {
        setreportSubmit(true);
        props?.reportIssueSuccess();
      }
    } catch (error) {
      setloader(false);
      handleApiError(error);
    }
    // props?.cancelModal();
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    validateFile(selectedFile);
  };

  const validateFile = (file: any) => {
    const validTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    if (!validTypes.includes(file?.type)) {
      setError(
        t(
          "Invalid file type. Only Jpeg, Png, Pdf files are allowed.",
          i18n.language
        )
      );
      return;
    }

    if (file.size > maxSize) {
      setError(
        t("File size exceeds the maximum limit of 5 MB.", i18n.language)
      );
      return;
    }

    setFile(file);
    setError("");
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    validateFile(droppedFile);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const removeItem = () => {
    setFile(null);
  };
  const backButtonHandle = () => {
    if (next) {
      setNext(false);
    } else {
      props?.cancelModal();
    }
  };

  return (
    <>
      {loader && (
        <FullLoader
          fullLoader={false}
          iconColor="#023f88"
          class="w-[100px] text-primary"
        />
      )}
      {/* {reportSubmit && <Success />} */}
      {!reportSubmit && (
        <div
          className={`z-[55] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50`}
        >
          <div
            className={`bg-white p-8 rounded-xl relative shadow-lg m-7 w-full md:max-w-[654px] mobileModal max-md:!block overflow-y-auto`}
          >
            <span
              onClick={() => {
                backButtonHandle();
              }}
              className="cursor-pointer absolute top-7 left-6 text-gray-500 hover:text-gray-700"
            >
              <Icon name="backarrow" />
            </span>

            <button
              className="absolute top-6 right-6 text-gray-500 hover:text-gray-700"
              onClick={() => {
                props?.cancelModal();
              }}
            >
              <Icon name="close" />
            </button>

            <div className="modal-content w-full mx-auto">
              <div className="flex flex-col text-center">
                <div className="mdlHead">
                  <div className="font-medium text-black text-2xl">
                    {t("Report an Issue", i18n.language)}
                  </div>
                  <p className="font-medium text-xs text-muted mt-3  max-w-[404px] m-auto mb-8">
                    {t(
                      "Please provide your details to help us resolve the OTP issue quickly. Your cooperation will help us deliver a smoother service experience",
                      i18n.language
                    )}
                  </p>
                </div>

                <div className="mdlBodyChild">
                  {!next && (
                    <div className="alpha">
                      <div className="flex md:gap-4 max-sm:flex-col">
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-xs text-black mb-[7px]">
                            {t("Title", i18n.language)}
                          </label>
                          <Select
                            classNamePrefix="reactSelect"
                            className={`customSelect text-left pt-[8px] border border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm text-sm text-black font-medium placeholder:text-[#979A9C] px-[15px] outline-none max-w-[516px] `}
                            options={prefixOptions}
                            placeholder={t("Select Prefix", i18n.language)}
                            value={
                              prefix ? { value: prefix, label: prefix } : null
                            }
                            onChange={(selectedOption) =>
                              setPrefix(
                                selectedOption ? selectedOption.value : ""
                              )
                            }
                          />
                        </div>
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-xs text-black mb-[7px]">
                            {t("Full Name", i18n.language)}
                          </label>
                          <input
                            className={`border border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-none max-w-[516px] `}
                            placeholder={t(
                              "Enter your full name",
                              i18n.language
                            )}
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex md:gap-4 max-sm:flex-col">
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-xs text-black mb-[7px]">
                            {t("Mobile", i18n.language)}
                          </label>
                          <PhoneInput
                            containerClass={`countryInput`}
                            country={"sa"} // Country code for Saudi Arabia
                            value={mobileNumber}
                            onChange={(
                              val,
                              data: { dialCode: string },
                              formattedValue
                            ) => {
                              setMobileNumber(val); // Update the full value including the country code
                            }}
                          />
                          {error === "Invalid Phone Number" && (
                            <span className="text-red-700 font-normal text-xs block mt-1">
                              {t("Invalid Phone Number", i18n.language)}
                            </span>
                          )}
                        </div>
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-xs text-black mb-[7px]">
                            {t("Email Address", i18n.language)} (
                            {t("Optional", i18n.language)})
                          </label>
                          <input
                            className={`border border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-none max-w-[516px] `}
                            placeholder={t(
                              "Enter Email Address",
                              i18n.language
                            )}
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {error === "Invalid email address" && (
                            <span className="text-red-700 font-normal text-xs block mt-1">
                              {t("Invalid email address",i18n.language)}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex md:gap-4 max-sm:flex-col">
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-xs text-black mb-[7px]">
                            {t("Registration Number", i18n.language)}
                          </label>
                          <input
                            className={`border border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-none max-w-[516px] `}
                            placeholder={t(
                              "Enter Registration Number",
                              i18n.language
                            )}
                            value={registrationNumber}
                            onChange={(e) =>
                              setRegistrationNumber(e.target.value)
                            }
                          />
                        </div>
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-xs text-black mb-[7px]">
                            {t("VIN Number", i18n.language)}
                          </label>
                          <input
                            className={`border border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-none max-w-[516px] `}
                            placeholder={t("Enter VIN Number", i18n.language)}
                            value={vinNumber}
                            onChange={(e) => setVinNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex md:gap-4 max-sm:flex-col">
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-xs text-black mb-[7px]">
                            {t("Reason Request", i18n.language)}{" "}
                          </label>
                          <textarea
                            className={`border border-[#D0D0D0] rounded-md   min-h-[86px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-none  w-full `}
                            placeholder={t("Enter Reason", i18n.language)}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                          />
                        </div>
                      </div>

                      {error && (
                        <div className="text-red-500 text-xs font-normal hidden">
                          {error}
                        </div>
                      )}
                    </div>
                  )}
                  {next && (
                    <div className="beta">
                      <div className="flex flex-col">
                        <div className="formGroup flex flex-col items-start w-full mb-7">
                          <label className="font-medium text-[15px] text-black mb-[7px]">
                            {t("Vehicle Details", i18n.language)}
                          </label>
                          <div className="bg-[#FAFAFA] py-4 px-[21px] flex flex-col items-start gap-1 w-full">
                            <span className="block font-medium text-sm text-primary">
                              {props?.plateData?.inputValue ||
                                props?.plateData?.value ||
                                props?.plateData?.customerVehicles
                                  ?.licensePlate}
                            </span>
                            <span className="block font-medium text-sm text-primary">
                              {props?.plateData?.data?.make ||
                                props?.plateData?.customerVehicles?.make}{" "}
                              {props?.plateData?.data?.model ||
                                props?.plateData?.customerVehicles?.model}{" "}
                              {props?.plateData?.data?.modelYear ||
                                props?.plateData?.customerVehicles?.modelYear}
                            </span>
                          </div>
                        </div>
                        <div className="formGroup flex flex-col items-start w-full mb-7">
                          <label className="font-medium text-[15px] text-black">
                            {t("Request to Link as", i18n.language)}
                          </label>
                          <div className="pt-4  flex flex-row gap-[47px] items-start w-full">
                            <div className="flex items-center justify-center gap-2">
                              <input
                                type="radio"
                                checked
                                name="type"
                                value={"Owner"}
                                className="w-[22px] h-[22px]"
                                onChange={() => setLink("REGISTEREDKEEPER")}
                              />
                              <label>{t("Owner", i18n.language)}</label>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <input
                                type="radio"
                                name="type"
                                value={"Holder"}
                                className="w-[22px] h-[22px]"
                                onChange={() => setLink("HOLDER")}
                              />
                              <label>{t("User", i18n.language)}</label>
                            </div>
                          </div>
                        </div>
                        <div className="formGroup flex flex-col items-start w-full mb-[18px]">
                          <label className="font-medium text-[15px] text-black mb-[7px]">
                            {t("Registered Owner", i18n.language)}
                          </label>
                          <Select
                            classNamePrefix="reactSelect"
                            className={`customSelect text-left pt-[8px] border border-[#D0D0D0] rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm text-sm text-black font-medium placeholder:text-[#979A9C] px-[15px] outline-none  `}
                            options={ownerType}
                            placeholder={t("Select Owner Type", i18n.language)}
                            onChange={(selectedOption) =>
                              setOwnerType(
                                selectedOption ? selectedOption.value : ""
                              )
                            }
                          />
                        </div>
                        <div className="formGroup flex flex-col items-start w-full mb-7">
                          <label className="font-medium text-[15px] text-black mb-[7px]">
                            {t("Registration Document", i18n.language)}
                          </label>
                          {!file && (
                            <div
                              className="bg-[#FAFAFA] relative py-6 px-7 flex flex-col items-center w-full border-[1px] border-dashed border-[#D0D0D0] rounded-md cursor-pointer"
                              onDrop={handleDrop}
                              onDragOver={handleDragOver}
                            >
                              <input
                                type="file"
                                onChange={(e) => {
                                  handleFileChange(e);
                                }}
                                className="opacity-0 absolute  cursor-pointer h-full top-0"
                              />
                              <div className="mb-3">
                                <Icon name="upload" />
                              </div>
                              <span className="block font-medium text-sm text-[#636363] mb-1">
                                {t(
                                  "Drag & drop your files here or",
                                  i18n.language
                                )}{" "}
                                <a className="text-primary underline">
                                  {t("choose files", i18n.language)}
                                </a>{" "}
                              </span>
                              <span className="block font-medium text-[11px] text-muted">
                                {t("Maxfilesize", i18n.language)}
                              </span>
                            </div>
                          )}
                          {file && (
                            <div
                              className="bg-[#FAFAFA] relative p-[20px] flex flex-row items-center w-full border-[1px] justify-start gap-3 border-[#D0D0D0] rounded-md"
                              onDrop={handleDrop}
                              onDragOver={handleDragOver}
                            >
                              <div className="mb-0">
                                <Icon name="upload" />
                              </div>
                              <span className="block font-medium text-sm text-[#636363]">
                                {file?.name}
                              </span>
                              <span
                                className="absolute rtl:left-2 ltr:right-2  cursor-pointer"
                                onClick={() => removeItem()}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="4.43652"
                                    y="5.70605"
                                    width="1.39411"
                                    height="13.9411"
                                    rx="0.697056"
                                    transform="rotate(-45 4.43652 5.70605)"
                                    fill="#636363"
                                  />
                                  <rect
                                    x="14.2939"
                                    y="4.72046"
                                    width="1.39411"
                                    height="13.9411"
                                    rx="0.697056"
                                    transform="rotate(45 14.2939 4.72046)"
                                    fill="#636363"
                                  />
                                </svg>
                              </span>
                            </div>
                          )}
                          {error && (
                            <p className="text-red-500 text-xs font-normal mt-2 text-center w-full">
                              {error}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="masterBtn mt-2 mb-2">
                  {!next && (
                    <button
                      className="h-12 text-sm sm:w-40 w-28 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                      disabled={
                        !prefix ||
                        !fullName ||
                        !mobileNumber ||
                        !registrationNumber ||
                        !reason ||
                        !vinNumber
                      }
                      onClick={handleNext}
                    >
                      {t("Next", i18n.language)}
                    </button>
                  )}
                  {next && (
                    <button
                      disabled={!ownerType}
                      className="h-12 text-sm sm:w-40 w-28 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                      onClick={handleSubmit}
                    >
                      {t("Submit", i18n.language)}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportIssue;
