interface customProps {
    active?: boolean;
    class?: string;
    iconColor:string;
    fullLoader:boolean
}
const FullLoader = (props: customProps) => {
    return (
        <div className={`${props?.fullLoader ? 'fixed top-0 bottom-0 h-screen w-full right-0 left-0 bg-white opacity-80 flex items-center justify-center z-[99]':'absolute top-0 bottom-0 h-full w-full right-0 left-0 bg-white opacity-80 flex items-center justify-center z-[99]'}`}>
            <svg version="1.1" id="L9" x="0px" y="0px" className={`${props?.class}`}
                viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
                <path fill={props?.iconColor ? props?.iconColor: '#000'} d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite" />
                </path>
            </svg>
        </div>
    )
}

export default FullLoader;