import { useEffect, useState } from "react";
import Icon from "../icons/Icons";
import CardLayout from "../common/Card";
import CardNames from "../../util/CardNames";
import Modal from "../modal";
import VerifyOTP from "../modal/child/VerifyOtp";
import apiService from "../../api/api";
import ReportIssue from "../modal/child/ReportIssue";
import { navigateTo } from "../../router/appRouter";
import { setItem, getItem } from "../../util/storageService";
import i18n from "../../i18n";
import { toast } from "react-toastify";
import PromptModal from "../modal/child/PromptModal";
import Success from "../modal/child/Succsess";
import { useTranslation } from "react-i18next";

interface customProps {
  cardActive: boolean;
  onSubmit: (nextcomponent: string) => void;
  edit?: string;
  flag?: string;
  manageBookingLogin?: boolean;
  editPrompt(card: any): void;
  search?: { plateNumber?: string; vin?: string };
}

const PlateNumber = (props: customProps) => {
  const [nextcomponent, setSetcomponent] = useState(CardNames?.ODO_MILEAGE);

  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isResentOtp, setIsResentOtp] = useState<boolean>(false);
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [reportIssue, setreportIssue] = useState<boolean>(false);
  const [backButtonAction, setbackButtonAction] = useState<boolean>(false);
  const [reschedule, setreschedule] = useState<boolean>(false);
  const [reportIssueSuccess, setreportIssueSuccess] = useState<boolean>(false);
  const [inputValue, setinputValue] = useState("");
  const [currentStep, setCurrentStep] = useState("");
  const [error, setError] = useState("");
  const [translation, setTranslation] = useState("");

  const [response, setResponse] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>([]);

  const cardData = getItem("userSummary");
  const card = cardData?.find(
    (item: { card_name: any }) => item.card_name === CardNames?.PLATE_NUMBER
  );
  const isReschedule = getItem("isReschedule") || false;
  const searchBy = getItem("search_by");
  const searchedPlateNumber = getItem("plate_number_manage");
  const searchedVinNumber = getItem("vin_number_manage");
  const [selectedOption, setSelectedOption] = useState(
    props?.search?.vin || searchBy == "vin" ? "vin" : "plateNumber"
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const userData = getItem("userDetails");
    const upcomingBooking = userData?.data?.data?.bookings?.upcoming || [];
    const inProgressBooking = userData?.data?.data?.bookings?.inProgress || [];
    const pendingBooking = userData?.data?.data?.bookings?.pending || [];

    if (
      (upcomingBooking?.length > 0 ||
        inProgressBooking?.length > 0 ||
        pendingBooking?.length > 0) &&
      !isReschedule
    ) {
      toast.success(
        `${t(
          "You have already booked an appointment.View or manage booking",
          i18n.language
        )}`
      );
      navigateTo(`/${i18n.language}/manage-booking`);
    }
  });

  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
    if (isReschedule) setSubmit(true);
  }, [props?.cardActive]);

  const submitLicencePlateData = () => {
    if (inputValue.trim() === "" && !props?.search?.plateNumber) {
      setError("Plate number cannot be empty");
    } else {
      setIsBtnDisable(true);
      setLoader(true);
      const data = {
        licensePlate: inputValue || props?.search?.plateNumber,
      };
      apiService
        .post("/app/customer/login-by-license-plate", data)
        .then((response) => {
          setLoader(false);
          setError("");
          setModal(true);
          setIsBtnDisable(false);
          setResponse({
            ...response?.data,
            ...{ inputValue },
          });
        })
        .catch((error) => {
          setLoader(false);
          setError(error?.response?.data?.message);
          setIsBtnDisable(false);
        });
    }
  };
  const submitVinData = () => {
    if (inputValue.trim() === "" && !props?.search?.vin) {
      setError("VIN cannot be empty");
    } else {
      setIsBtnDisable(true);
      setLoader(true);
      const data = {
        vin: inputValue ? `*${inputValue}*` : `*${props?.search?.vin}*`,
      };
      apiService
        .post("/app/customer/login-by-license-plate", data)
        .then((response) => {
          setLoader(false);
          setError("");
          setModal(true);
          setIsBtnDisable(false);
          setResponse({
            ...response?.data,
            ...{ inputValue },
          });
        })
        .catch((error) => {
          setLoader(false);
          setError(error?.response?.data?.message);
          setIsBtnDisable(false);
        });
    }
  };
  const handleOptionChange = (event: any) => {
    const value = event?.target?.value;
    setSelectedOption(value);
    setItem("search_by", value);
    setinputValue("");
    setError("");
  };
  const toHome = () => {
    // clearStorage();
    navigateTo(`/${i18n.language}/`);
  };
  const confirmData = () => {
    const summary = getItem("userSummary");

    props.onSubmit(nextcomponent);
    setItem("currentStep", CardNames?.ODO_MILEAGE);
    setCurrentStep(CardNames?.ODO_MILEAGE);
    const cardNamesToRemove = [
      CardNames?.ODO_MILEAGE,
      CardNames?.DEALER_LOCATION,
      CardNames?.REQUESTED_SERVICE,
      CardNames?.DATE_TIME,
    ];
    const filteredData = summary.filter(
      (item: { card_name: string }) =>
        !cardNamesToRemove.includes(item.card_name)
    );
    if (filteredData) {
      setItem("userSummary", filteredData);
    }
  };
  const closeModal = () => {
    setModal(false);
    setreschedule(false);
    setIsResentOtp(false);
  };

  const resendOtp = () => {
    if (searchBy == "vin" || props?.search?.vin || searchedVinNumber) {
      submitVinData();
    } else submitLicencePlateData();
    setIsResentOtp(true);
    setIsBtnDisable(false);
  };

  const VerifyOtp = (data: any) => {
    closeModal();
    setSubmit(true);
    setItem("userDetails", data);
    setItem("currentStep", CardNames?.PLATE_NUMBER);
  };

  const reportIssueMdl = () => {
    setreportIssue(true);
    setModal(false);
  };
  const reportIssueSuccessModal = () => {
    setreportIssueSuccess(true);
  };

  useEffect(() => {
    if (!inputValue) {
      setTranslation("");
    }
    if (
      searchBy == "plateNumber" ||
      props?.search?.plateNumber ||
      searchedPlateNumber
    ) {
      getTransliteratedLetters(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    const currentStep = getItem("currentStep");
    const searchBy = getItem("search_by");
    setCurrentStep(currentStep);
    const userData = getItem("userDetails");
    if (userData) setUserInfo(userData?.data?.data?.user);
    const plateNumber =
      userData?.data?.data?.user?.customerVehicles?.licensePlate;
    const vin = userData?.data?.data?.user?.customerVehicles?.vin;

    if (searchBy == "plateNumber" && plateNumber) {
      setinputValue(plateNumber);
      setSubmit(true);
    }
    if (searchBy == "vin" && vin) {
      setinputValue(vin);
      setSubmit(true);
    }
  }, [isSubmit, currentStep]);

  const bacIconAction = () => {
    setbackButtonAction(true);
  };

  const toggleCard = () => {
    setSubmit(false);
    setItem("currentStep", CardNames?.PLATE_NUMBER);
  };

  const editPlateNumber = () => {
    const lang = getItem("language");
    localStorage.clear();
    if (!lang) setItem("language", "en");
    else setItem("language", lang);
    window.location.reload();
  };
  const editVinNumber = () => {
    localStorage.clear();
    window.location.reload();
    setItem("search_by", "vin");
  };
  const openPrompt = () => {
    setreschedule(true);
  };

  const getTransliteratedLetters = (licensePlate: string) => {
    const isArabic = hasArabicCharacters(licensePlate);
    const arLetters = getArLetters(licensePlate);
    const enLetters = getEnLetters(licensePlate);
    const enNumbers = getEnNumbers(licensePlate);
    const isArLettersInvalid = arLetters === "Invalid";
    const isEnLettersInvalid = enLetters === "Invalid";
    const isEnNumbersInvalid = enNumbers === "Invalid";

    if (isArabic) {
      if (isEnNumbersInvalid || isEnLettersInvalid) {
        setError(t("Invalid Plate Number", i18n.language));
        setIsBtnDisable(true);
      } else {
        setTranslation(`${enNumbers}${enLetters}`);
      }
      setIsBtnDisable(false);
    }
    if (isEnNumbersInvalid || isArLettersInvalid) {
      setError(t("Invalid Plate Number", i18n.language));
      setIsBtnDisable(true);
    } else {
      setTranslation(`${enNumbers}${arLetters}`);
    }
    setIsBtnDisable(false);
  };
  return (
    <>
      {reschedule && (
        <Modal
          closeIcon={true}
          closeModal={closeModal}
          customClass={"max-w-[540px] md:!max-w-[540px]"}
          backButton={() => {}}
        >
          {searchBy == "plateNumber" && (
            <PromptModal
              title={t("Edit Plate Number!", i18n.language)}
              subTitle={t(
                "Editing the plate number will clear all filled data!.",
                i18n.language
              )}
              cancelModal={closeModal}
              confirmModal={editPlateNumber}
            />
          )}
          {searchBy == "vin" && (
            <PromptModal
              title={t("Edit Vin Number", i18n.language)}
              subTitle={t(
                "Editing the vin number will clear all filled data!.",
                i18n.language
              )}
              cancelModal={closeModal}
              confirmModal={editVinNumber}
            />
          )}
        </Modal>
      )}
      {reportIssue && (
        <ReportIssue
          plateData={
            response
              ? {
                  ...response,
                  ...{
                    value: props?.search?.plateNumber
                      ? props?.search?.plateNumber
                      : props?.search?.vin,
                  },
                }
              : userInfo
          }
          backButtonAction={backButtonAction}
          cancelModal={() => setreportIssue(false)}
          reportIssueSuccess={reportIssueSuccessModal}
        />
      )}
      {reportIssueSuccess && <Success />}
      {modal && (
        <Modal closeIcon={true} closeModal={closeModal} backButton={() => {}}>
          <VerifyOTP
            flag={props.flag}
            reportIssue={reportIssueMdl}
            data={response}
            resendOTP={resendOtp}
            verifyOTP={VerifyOtp}
            manageBookingLogin={props?.manageBookingLogin}
          />
        </Modal>
      )}

      <CardLayout
        bg={`${isSubmit ? "border-[#E9E9E9] border" : ""} ${
          props.cardActive ? "bg-[#FAFAFA] border-0" : ""
        }`}
      >
        <div className="relative">
          <div className="cardHeader flex items-center relative">
            <Icon name={isSubmit ? "activeStep" : "inactiveStep"} />
            <div className="flex items-start flex-col ml-[15px]">
              <label
                className={`font-medium text-black block rtl:mr-2 ${
                  props.cardActive ? "font-medium text-2xl" : "text-lg"
                }`}
              >
                {searchBy == "vin" || props?.search?.vin || searchedVinNumber
                  ? `${t("VIN Number", i18n.language)}`
                  : `${t("Plate Number", i18n.language)}`}
              </label>
              {isSubmit && (
                <div>
                  <span className="font-medium text-sm  flex gap-4">
                    {searchBy == "plateNumber" ||
                    props?.search?.plateNumber ||
                    searchedPlateNumber
                      ? `${t("Plate Number", i18n.language)}`
                      : `${t("VIN Number", i18n.language)}`}
                    :
                    {searchBy == "plateNumber" ||
                    props?.search?.plateNumber ||
                    searchedPlateNumber
                      ? inputValue || props?.search?.plateNumber
                      : inputValue || props?.search?.vin}
                    {!isReschedule && (
                      <span
                        className="flex items-center justify-between gap-1 cursor-pointer"
                        onClick={() => openPrompt()}
                      >
                        <Icon name="edit" fillColor="#023f88" />
                        <span className="font-medium text-xs text-primary">
                          {t("Edit", i18n.language)}
                        </span>
                      </span>
                    )}
                  </span>
                  <span
                    onClick={() => {
                      props?.editPrompt(card);
                    }}
                    className={`${
                      isReschedule || currentStep === CardNames?.PLATE_NUMBER
                        ? "hidden"
                        : ""
                    } absolute rtl:left-0 ltr:right-0 cursor-pointer top-5`}
                  >
                    <Icon name="arrowLarge" />
                  </span>
                </div>
              )}
            </div>
          </div>
          {!isSubmit && props?.cardActive && (
            <div>
              <div className="radioOptions mt-8 md:ml-10 md:flex-row  text-xm flex items-center gap-4">
                <label className="mr-8 flex items-center rtl:gap-3">
                  <input
                    type="radio"
                    value="plateNumber"
                    checked={selectedOption === "plateNumber"}
                    onChange={handleOptionChange}
                    className="cursor-pointer mr-2 accent-primary overflow-hidden w-[22px] h-[22px] rounded-[5px] border-2 border-[#E9E9E9]"
                  />
                  <span className="text-sm">
                    {t("Plate Number", i18n.language)}
                  </span>
                </label>
                <label className="flex items-center rtl:gap-3">
                  <input
                    type="radio"
                    value="vin"
                    checked={selectedOption === "vin"}
                    onChange={handleOptionChange}
                    className="cursor-pointer mr-2 accent-primary overflow-hidden w-[22px] h-[22px] rounded-[5px] border-2 border-[#E9E9E9]"
                  />
                  <span className="text-sm">
                    {t("VIN Number", i18n.language)}
                  </span>
                </label>
              </div>

              {selectedOption === "plateNumber" && (
                <div>
                  <div className="cardBody mt-[20px] md:ml-[37px]">
                    {/* <label className="font-medium text-[15px] block mb-[7px]">
                      Your plate number
                    </label> */}
                    <input
                      className={`bg-white rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-1 max-w-[516px] ${
                        error ? "border border-red-500" : ""
                      }`}
                      placeholder={`${t(
                        "Enter your plate number",
                        i18n.language
                      )}`}
                      value={
                        inputValue ? inputValue : props?.search?.plateNumber
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        setItem("search_by", "plateNumber");
                        setinputValue(value);
                        setError("");
                      }}
                      readOnly={!!props?.search?.plateNumber}
                    />
                    {error && (
                      <span className="text-red-500 text-xs block mt-1">
                        {error}
                      </span>
                    )}
                    <div className="infoItem flex items-center gap-[6px] mt-3">
                      <span className="font-medium text-xs  contents">
                        {translation}
                      </span>
                    </div>
                    <div className="infoItem flex items-center gap-[6px] mt-3">
                      <Icon name="info" />
                      <span className="font-medium text-xs  contents">
                        {t(
                          "Enter your vehicle’s plate number in the following format: 1234JKL",
                          i18n.language
                        )}
                      </span>
                    </div>
                    <div className="btnAreaWrapper mt-8 flex gap-4">
                      <div className="btnArea">
                        <button
                          className="h-12 flex items-center justify-center gap-1 text-sm w-28 md:w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                          onClick={() => submitLicencePlateData()}
                          disabled={
                            (!inputValue && !props?.search?.plateNumber) ||
                            !!error ||
                            isBtnDisable
                          }
                        >
                          {!loader || isResentOtp ? (
                            <span>{t("Submit", i18n.language)}</span>
                          ) : (
                            <Icon width={25} name="spinner" />
                          )}
                        </button>
                      </div>
                      {props?.manageBookingLogin && (
                        <div className="btnArea">
                          <button
                            className="h-12 text-sm w-28 md:w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md"
                            onClick={() => toHome()}
                          >
                            <span>{t("Go Home", i18n.language)}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {selectedOption === "vin" && (
                <div>
                  <div className="cardBody mt-[20px] md:ml-[37px]">
                    {/* <label className="font-medium text-[15px] block mb-[7px]">
                      Please enter your VIN
                    </label> */}
                    <input
                      className={`bg-white rounded-md w-full h-[54px] placeholder:font-medium placeholder:text-sm placeholder:text-[#979A9C] px-[15px] py-[15px] outline-1 max-w-[516px] ${
                        error ? "border border-red-500" : ""
                      }`}
                      placeholder={`${t(
                        "Enter your VIN number",
                        i18n.language
                      )}`}
                      value={inputValue ? inputValue : props?.search?.vin}
                      onChange={(e) => {
                        setinputValue(e.target.value);
                        setError("");
                      }}
                    />
                    {error && (
                      <span className="text-red-500 text-xs block mt-1">
                        {error}
                      </span>
                    )}

                    {/* <div className="infoItem flex items-center gap-[6px] mt-3">
                    <span className="font-medium text-xs  contents">
                      {getTransliteratedLetters(inputValue)}
                    </span>
                  </div> */}
                    <div className="infoItem flex items-center gap-[6px] mt-6">
                      <Icon name="info" />
                      <span className="font-medium text-xs  contents">
                        {t(
                          "Enter your VIN number partially or in full:NMTBU4EE50R123456 or 0R123456.",
                          i18n.language
                        )}
                      </span>
                    </div>
                    <div className="btnAreaWrapper mt-8 flex gap-4">
                      <div className="btnArea">
                        <button
                          className="h-12 flex items-center justify-center gap-1 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                          onClick={() => submitVinData()}
                          disabled={
                            (!inputValue && !props?.search?.vin) || isBtnDisable
                          }
                        >
                          {!loader || isResentOtp ? (
                            <span>{t("Submit", i18n.language)}</span>
                          ) : (
                            <Icon width={25} name="spinner" />
                          )}
                        </button>
                      </div>
                      {props?.manageBookingLogin && (
                        <div className="btnArea">
                          <button
                            className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md"
                            onClick={() => toHome()}
                          >
                            <span>{t("Go Home", i18n.language)}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {currentStep === CardNames?.PLATE_NUMBER && (
            <div className="cardBody mt-[28px] md:ml-[37px]">
              <div className="carDetails">
                <div>
                  <label className="font-medium text-[15px] block mb-[7px]">
                    {userInfo?.fullName}
                  </label>
                  <div className="addressDiv mt-[17px]">
                    <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                      <span className="text-xs font-medium block w-[180px] text-left rtl:ml-2 rtl:text-right">
                        {t("Mobile", i18n.language)}
                      </span>
                      <span className="text-sm font-medium block text-black">
                        {userInfo?.primaryPhone}
                      </span>
                    </div>
                    <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                      <span className="text-xs font-medium block w-[180px] text-left rtl:ml-2 rtl:text-right">
                        {t("Email Address", i18n.language)}
                      </span>
                      <span className="text-sm font-medium block text-black lowercase">
                        {userInfo?.primaryEmail}
                      </span>
                    </div>
                    <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                      <span className="text-xs font-medium block w-[180px] text-left rtl:ml-2 rtl:text-right">
                        {t("Address", i18n.language)}
                      </span>
                      <span className="text-sm font-medium block text-black">
                        {userInfo?.addresses &&
                          userInfo?.addresses.length > 0 && (
                            <>
                              {userInfo?.addresses[0]?.line1},{" "}
                              {userInfo?.addresses[0]?.street},{" "}
                              {userInfo?.addresses[0]?.city},{" "}
                              {userInfo?.addresses[0]?.zip},{" "}
                              {userInfo?.addresses[0]?.country}
                            </>
                          )}
                      </span>
                    </div>

                    <div className="pt-3 pb-6">
                      <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="font-medium text-[15px] block mb-[7px]">
                    {userInfo?.customerVehicles?.make}
                  </label>
                  <div className="addressDiv mt-[17px]">
                    <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                      <span className="text-xs font-medium block w-[180px] text-left rtl:ml-2 rtl:text-right">
                        {t("Model Year", i18n.language)}
                      </span>
                      <span className="text-sm font-medium block text-black">
                        {userInfo?.customerVehicles?.modelYear}
                      </span>
                    </div>
                    <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                      <span className="text-xs font-medium block w-[180px] text-left rtl:ml-2 rtl:text-right">
                        {t("Model", i18n.language)}
                      </span>
                      <span className="text-sm font-medium block text-black">
                        {userInfo?.customerVehicles?.model}{" "}
                        {userInfo?.customerVehicles?.variant}
                      </span>
                    </div>
                    <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                      <span className="text-xs font-medium block w-[180px] text-left rtl:ml-2 rtl:text-right">
                        {t("Registration Number", i18n.language)}
                      </span>
                      <span className="text-sm font-medium block text-black">
                        {userInfo?.customerVehicles?.licensePlate}
                      </span>
                    </div>
                    <div className="flex flex-row items-center mb-3 max-md:flex-col max-md:items-start">
                      <span className="text-xs font-medium block w-[180px] text-left rtl:ml-2 rtl:text-right">
                        {t("VIN", i18n.language)}
                      </span>
                      <span className="text-sm font-medium block text-black">
                        {userInfo?.customerVehicles?.vin}
                      </span>
                    </div>
                    <div className="pt-3 pb-3">
                      <div className="h-[2px] bg-[#E7E7E7] w-full"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btnAreaWrapper mt-8 flex gap-4">
                <div className="btnArea">
                  <button
                    className="h-12 text-sm w-32 md:w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                    onClick={() => confirmData()}
                  >
                    {t("Confirm", i18n.language)}
                  </button>
                </div>

                <div className="btnArea">
                  <button
                    className="h-12 text-sm w-36 md:w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md"
                    onClick={() => reportIssueMdl()}
                  >
                    {t("Report an Issue", i18n.language)}
                  </button>
                  {/* {reportIssueSuccess && <Success />} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </CardLayout>
    </>
  );
};

export default PlateNumber;

// Sample logics for arabic license plate conversions
// Todo: make it utility function and optimize
const allowedLettersMapWithEnKey: any = {
  A: "ا",
  B: "ب",
  J: "ح",
  D: "د",
  R: "ر",
  S: "س",
  X: "ص",
  T: "ط",
  E: "ع",
  G: "ق",
  K: "ك",
  L: "ل",
  Z: "م",
  N: "ن",
  H: "هـ",
  U: "و",
  V: "ى",
};

const allowedLettersMapWithArKey: any = {
  ا: "A",
  ب: "B",
  ح: "J",
  د: "D",
  ر: "R",
  س: "S",
  ص: "X",
  ط: "T",
  ع: "E",
  ق: "G",
  ك: "K",
  ل: "L",
  م: "Z",
  ن: "N",
  ه: "H", // Ar representation should be 'هـ'
  و: "U",
  ى: "V",
};

const arNumbers: string[] = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];

const _removeSpaces = (str: string) => {
  return str.replace(/\s/g, "");
};
const _getDigits = (plateNumber: string) => {
  return (
    _removeSpaces(plateNumber)
      .match(/\d+|[٠-٩]/g)
      ?.join("") || ""
  );
};

const _getLetters = (plateNumber: string) => {
  return _removeSpaces(plateNumber).replace(/\d+|[٠-٩]/g, "");
};

export const getEnLetters = (plateNumber: string) => {
  const letters = _getLetters(plateNumber);
  const englishRegx = /^[a-zA-Z]+$/;
  if (englishRegx.test(letters)) {
    return letters.toUpperCase().replace(/\s/g, "");
  }
  let stringBuilider = [];
  for (const letterIt of letters.split("")) {
    if (letterIt == "ـ" || letterIt == ".") {
      continue;
    }
    const value = allowedLettersMapWithArKey[letterIt];
    if (value == undefined) {
      // throw new Error(`Invalid letter ${letterIt}`);
      return "Invalid";
    }
    stringBuilider.push(value);
  }
  return stringBuilider.reverse().join(" ");
};

export const getArLetters = (plateNumber: string) => {
  const letters = _getLetters(plateNumber).toUpperCase();
  const englishRegx = /^[a-zA-Z.]+$/;
  if (englishRegx.test(letters)) {
    let stringBuilider = [];
    for (const letterIt of letters.split("")) {
      let value = "";
      if (letterIt == "H") {
        value += "ـ";
      }
      if (letterIt === ".") {
        continue;
      }
      value = allowedLettersMapWithEnKey[letterIt];
      if (value == undefined || value == "ـ") {
        // throw new Error(`Invalid letter ${letterIt}`);
        return "Invalid";
      }
      stringBuilider.push(value);
    }
    return stringBuilider.reverse().join(" ");
  }

  let stringBuilider = [];

  for (const letterIt of letters.split("")) {
    if (letterIt == "ـ") {
      continue;
    }
    if (letterIt == "ه") {
      stringBuilider.push("هـ");
    } else {
      stringBuilider.push(letterIt);
    }
  }

  return stringBuilider.join(" ");
};

export const hasArabicCharacters = (text: string): boolean => {
  if (!text) return false;
  var regex = new RegExp(
    "[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]"
  );
  return regex.test(text);
};
export const getArNumbers = (plateNumber: string) => {
  const arNumRegex = /^[٠-٩]+$/;
  const numbers = _getDigits(plateNumber);
  if (arNumRegex.test(numbers)) {
    return numbers.replace(/\s/g, "").split("").join("");
  }

  let stringBuilider = [];
  for (const itt of numbers.split("")) {
    const value = arNumbers.indexOf(itt);
    if (value == undefined) {
      // throw new Error(`Invalid number format at ${itt}`);
      return "Invalid";
    }
    stringBuilider.push(arNumbers[value]);
  }

  return stringBuilider.join("");
};

export const getEnNumbers = (plateNumber: string) => {
  const arNumRegex = /^[٠-٩]+$/;
  const numbers = _getDigits(plateNumber);
  if (arNumRegex.test(numbers)) {
    let stringBuilider = [];
    for (const itt of numbers.split("")) {
      const value = arNumbers.indexOf(itt);
      if (value == undefined) {
        // throw new Error(`Invalid number format at ${itt}`);
        return "Invalid";
      }
      stringBuilider.push(value);
    }

    return stringBuilider.join("");
  }

  return numbers.replace(/\s/g, "").split("").join(" ");
};
